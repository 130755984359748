import React, { Component } from "react";
import { logout } from "../Helpers/Helpers";
import NewProduct from  "../Affiliate/newProducts";

import {
  Container,
  Row,
  Col,
  Tab,
  Navbar,
  Nav,
  Form,
  Button,
  ProgressBar,
  Image,
  OverlayTrigger,
  Tooltip,
  Card,
} from "react-bootstrap";
import "./css/dashboard.css";
import Leftpanel from "./Section/LeftPanel";
import validation from "../Validate/validator";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import NumberFormat from "react-number-format";
import FeedBack from "./Section/FeedBack";
import { BASE_URL,SPONSOR_URL,JOBSEEKER_URL,BASE_API_URL } from "../Config";
import {
  Chart,
  Axis,
  Line,
  Point,
  LineAdvance,
  Interval,
  Legend,
  Coordinate,
} from "bizcharts";
import ShareAffiliateLink from "./Section/ShareAffiliateLink";
import DataSet from "@antv/data-set";
import moment from "moment";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';

export default class AffiliateDashboard extends Component {
  constructor() {
    super();
    this.state = {
      showMaskURL :false,
      products : [],
      copySuccessMes : {},
      ShareModalNew : {},
      commission_rate_tripvalet : 0,
      commission_rate_tripvalet_incentive : 0,
      commission_rate_yachtkarma : 0,
      tripvaletIncentive_affiliate : 0,
      tripvalet_affiliate : 0,
      yatchkarma_affiliate : 0,
      affiliate_code : "",
      userDetails: {},
      sponsorDetails: "",
      ShareModal: false,
      ShareModal2: false,
      ShareModal3: false,
      ShareModal4: false,
      urlPath: "/affiliate-dash",
      reddemCode: "",
      Msg: "",
      MsgType: "",
      loader: "hidden",
      dashboardInfo: {},
      copySuccessSP: "",
      copySuccessSP2 : "",
      copySuccessJO: "",
      copySuccessAF : "",
      affiliate_URL : "",
      sponsor_URL: "",
      sponsor_count: "",
      Jobseeker_URL: "",
      Jobseeker_count: "",
      commissionList: "",
      total_Commission: "",
      due_commission: "",
      monthcommission: "",
      percentage: "",
      help: "",
      jobseekerCommission: "",
      userRole: "",
      FromData: {
        start_date: "",
        end_date: "",
      },
      FromDataError: {
        start_date: "false",
        end_date: "false",
      },
      barData: [],
      topMember: [],
      start_date: moment().startOf('month').toDate(),
      end_date:  moment().endOf('month').toDate(),
      startDateErr: "",
      endDateErr: "",
    };
    this.searchChart = this.searchChart.bind(this)
    // this.handaleChange = this.handaleChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)

    //bind
  }

  // validateForm(errors) {
  //   let valid = true;
  //   let sta = this;
  //   let FromDataError = this.state.FromDataError;

  //   for (const [key, value] of Object.entries(errors)) {
  //     if (value.length > 0) {
  //       if (value == "false") {
  //         FromDataError[key] = "This field is required";
  //         sta.setState({ FromDataError });
  //       }

  //       valid = false;
  //     }
  //   }

  //   return valid;
  // }

  // handaleChange(data) {
  //   let sta = this;
  //   let FromDataError = this.state.FromDataError;

  //   let name = data.target.name;
  //   let value = "";
  //   let FromData = this.state.FromData;

  //   value = data.target.value;
  //   FromData[name] = value;
  //   this.setState({ FromData }, () => { });

  //   //validate from
  //   var valid_obj = {
  //     value: value,
  //     rules: data.target.getAttribute("validaterule"),
  //     message: data.target.getAttribute("validatemsg"),
  //   };

  //   validation(valid_obj).then((error) => {
  //     FromDataError[name] = error;
  //     this.setState({ FromDataError }); //from error state
  //   });
  // }

  validateForm() {
    let startDateErr = "";
    let endDateErr = "";

    if (!this.state.start_date) {
      startDateErr = "required";
    } else {
      startDateErr = ""
    }
    if (!this.state.end_date) {
      endDateErr = "required";
    }
    else if (this.state.start_date > this.state.end_date) {
      endDateErr = "To date must be greater than from date";
    }
    if (startDateErr || endDateErr) {
      this.setState({ startDateErr, endDateErr });
      return false;
    } else {
      this.setState({ startDateErr: "", endDateErr: "" });
      return true;
    }
  }


  handleStartDateChange(startD) {
    this.setState({
      start_date: startD,

    })
  }
  handleEndDateChange(endD) {
    this.setState({
      end_date: endD,

    })
  }

  handleReedemClear() {
    this.setState({ reddemCode: "" });
    this.setState({ Text: "" });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let sta = this;

    sta.getReferralBarChart();
    sta.top5Memberperformance();

    try {
      if (localStorage.getItem("gs_user") != null) {
        let userDetails = JSON.parse(localStorage.getItem("gs_user"));

        let sponsorDetails = JSON.parse(
          localStorage.getItem("gs_sponser_details")
        );
        this.setState({ userDetails, userRole: userDetails.roleID }, () => {
          if (sponsorDetails != null) {
            sta.setState({ sponsorDetails: sponsorDetails });
          }
        });

        sta.setState({ loader: "visible" }); ///loader on
        GET_API({
          token: localStorage.getItem("gs_token"),
          end_point: `/affiliate-count-list?app=${BASE_URL}`,
        })
          .then((resp) => {
            if (resp.status == "success") {
              localStorage.setItem("Jobseeker_URL", resp.data.Jobseeker_URL);
              this.state.affiliate_code = resp.data.affiliate_code
              let Jobseeker_count = resp.data.Jobseeker_count;
              let sponsor_count = resp.data.sponsor_count;
              let Jobseeker_URL = resp.data.Jobseeker_URL;
              let sponsor_URL = resp.data.sponsor_URL;
              // let sponsor_URL = `${SPONSOR_URL}?acode=${resp.data.affiliate_code}`;
              // let Jobseeker_URL = `${JOBSEEKER_URL}?acode=${resp.data.affiliate_code}`;

              let total_Commission = resp.data.total_Commission;
              let due_commission = resp.data.due_commission;
              let monthcommission = resp.data.monthcommission;
              let percentage;
              if(resp.data.nexgen_sponsor_commission.sponsor_commission_type == 'percentage'){
                percentage = resp.data.nexgen_sponsor_commission.sponsor_commission_rate + '%';
              }else{
                percentage = '$'+resp.data.nexgen_sponsor_commission.sponsor_commission_rate;
              }

              let jobseekerCommission;
              if(resp.data.nexgen_jobseeker_commission.jobseeker_commission_type == 'percentage'){
                jobseekerCommission = resp.data.nexgen_jobseeker_commission.jobseeker_commission_rate + '%';
              }else{
                jobseekerCommission = '$'+resp.data.nexgen_jobseeker_commission.jobseeker_commission_rate;
              }

              this.setState({ affiliate_URL : BASE_URL+`#/affiliate-signup/${this.state.affiliate_code}/?pagesource=NEXGEN` });
              this.setState({ sponsor_URL });
              this.setState({ sponsor_count });
              this.setState({ Jobseeker_URL });
              this.setState({ Jobseeker_count });
              this.setState({ total_Commission });
              this.setState({ due_commission });
              this.setState({ percentage });
              this.setState({ monthcommission });
              this.setState({ jobseekerCommission });
            }
            sta.setState({ loader: "hidden" }); ///loader off
          })
          .catch((ee) => {
            sta.setState({ loader: "hidden" }); ///loader off

            console.log("/affiliatecountERR", ee);
          });

        sta.setState({ loader: "visible" }); ///loader on
        GET_API({
          token: localStorage.getItem("gs_token"),
          end_point: "/commission-details",
        })
          .then((resp1) => {
            if (resp1.status == "success") {
              let commissionList = resp1.data;

              sta.setState({ commissionList });
            }
            sta.setState({ loader: "hidden" }); ///loader off
          })
          .catch((ee) => {
            sta.setState({ loader: "hidden" }); ///loader off

            console.log("/affiliatecountERR", ee);
          });
      }
    } catch (error) { }

    //help api
    let requestHelp = {
      end_point: "/help-details?key=affiliate_dashboard",

      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestHelp)
      .then((resph) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resph.status == "success") {
          let help = resph.data.value;
          sta.setState({ help });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });
      this.fetchNexgenDashboard();
      this.searchChart();
  }

  handleClose() {
    if (this.state.ShareModal) {
      this.setState({ ShareModal: false });
    } else {
      this.setState({ ShareModal: true }, () => { });
    }
  }
  handleCloseNew(id) {
    if (this.state.ShareModalNew[id]) {
      this.setState({ ShareModalNew: {[id] : false} });
    } else {
      this.setState({ ShareModalNew: {[id] : true} });
    }
  }

  handleClose2() {
    if (this.state.ShareModal2) {
      this.setState({ ShareModal2: false });
    } else {
      this.setState({ ShareModal2: true }, () => { });
    }
  }
  handleClose3() {
    if (this.state.ShareModal3) {
      this.setState({ ShareModal3: false });
    } else {
      this.setState({ ShareModal3: true }, () => { });
    }
  }
  handleClose4() {
    if (this.state.ShareModal4) {
      this.setState({ ShareModal4: false });
    } else {
      this.setState({ ShareModal4: true }, () => { });
    }
  }
  copyKnowledgeBase = (e, classId,jobseekerText) => {
   
    navigator.clipboard.writeText(jobseekerText);
    document.execCommand("copy");

    e.target.focus();
    this.setState({ copySuccessMes: { [classId]: "Copied!" } });
    setTimeout(() => {
      this.setState({ copySuccessMes: { [classId]: "" } });
    }, 2000);
  }

  copyToClipboard = (e, classId) => {
    var jobseekerText = document
      .getElementById(classId)
      .getAttribute("data-value");

    navigator.clipboard.writeText(jobseekerText);
    document.execCommand("copy");

    e.target.focus();
    this.setState({ copySuccessMes: { [classId]: "Copied!" } });
    setTimeout(() => {
      this.setState({ copySuccessMes: { [classId]: "" } });
    }, 2000);

 if(classId == "affiliateId"){
      this.setState({ copySuccessSP2: "Copied!" });
      setTimeout(() => {
        this.setState({ copySuccessSP2: "" });
      }, 2000);
    }
  };

  fetchNexgenDashboard(){
    let sta = this;
    var formdata = new FormData();
    formdata.append("app_name", "NEXGEN");
   
    let request = {
      end_point : '/nexgen-dashboard',
      token: localStorage.getItem("gs_token"),
      formdata : formdata
  };
  
  sta.setState({ loader: 'visible' })///loader on
  POST_API(request).then(resp=>{
    sta.setState({ loader: 'hidden' })///loader on
        if(resp.status == 'success')
        {
          sta.setState({
            showMaskURL : resp.data.showMaskURL == 1 ? true :false,
            products : resp.data.products,
            total_Commission : +(sta.state.total_Commission + (+resp.data.lifetime_commission)),
            due_commission : +(sta.state.due_commission + (+resp.data.unpaid_commission)) 
          })
        }else{
          sta.setState({ 
            products : []
          })
        }
    }).catch((ee)=>{
      sta.setState({ loader: 'hidden' })///loader on
      
      console.log('/code list ERR',ee)
    });
  }

  searchChart(e) {

    if(e){
      e.preventDefault();
    }
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    if (localStorage.getItem("roleID") == 8 || localStorage.getItem("roleID") == 10 ) {
      if (this.validateForm()) {
        let start_date = this.state.start_date
        let end_date = this.state.end_date

        sta.setState({ loader: 'visible' })///loader on

        let requestsearch = {
          end_point: '/recruiter-referral-statistics?start_date=' + moment(start_date).format("YYYY-MM-DD") + '&end_date=' + moment(end_date).format("YYYY-MM-DD"),
          token: localStorage.getItem("gs_token")

        };

        POST_API(requestsearch).then(resp3 => {

          sta.setState({ loader: 'hidden' })///loader off

          if (resp3.status == 'success') {

            let barData = resp3.data;

            sta.setState({ barData });

          }
        }).catch((ee) => {

          sta.setState({ loader: 'hidden' })///loader off

          console.log('/affiliatecountERR', ee)
        });

      }
    } else if (localStorage.getItem("roleID") == 9) {
      if (this.validateForm()) {
        let start_date = this.state.start_date
        let end_date = this.state.end_date


        sta.setState({ loader: 'visible' })///loader on

        let requestsearch = {
          end_point: '/gwiaf-referral-statistics?start_date=' + moment(start_date).format("YYYY-MM-DD") + '&end_date=' + moment(end_date).format("YYYY-MM-DD"),
          token: localStorage.getItem("gs_token")

        };

        POST_API(requestsearch).then(resp3 => {

          sta.setState({ loader: 'hidden' })///loader off

          if (resp3.status == 'success') {

            let barData = resp3.data;

            sta.setState({ barData });

          }
        }).catch((ee) => {

          sta.setState({ loader: 'hidden' })///loader off

          console.log('/affiliatecountERR', ee)
        });

      }
    }

  };

  shareOnFacebook(classId) {
    var fburl;

    var fburl = document
      .getElementById(classId)
      .getAttribute("data-value");

    let t = "Affiliate link";
    window.open(
      "http://www.facebook.com/sharer.php?u=" +
      encodeURIComponent(fburl) +
      "&t=" +
      encodeURIComponent(t),
      "sharer",
      "toolbar=0,status=0,width=626,height=436"
    );
  }
  shareOnLinkedin(classId) {
    //var fburl=BASE_URL;
    var fburl;
    var fburl = document
    .getElementById(classId)
    .getAttribute("data-value");

    var title = "Affiliate link";
    //var text = "Replace this with your share copy.";
    window.open(
      "http://www.linkedin.com/shareArticle?mini=true&url=" +
      encodeURIComponent(fburl),
      "sharer",
      "left=0,top=0,width=626,height=436,personalbar=0,toolbar=0,scrollbars=yes,resizable=yes"
    );
  }

  shareOnTwitter(classId) {
    var fburl;

    var fburl = document
    .getElementById(classId)
    .getAttribute("data-value");
    var title = "Affiliate link";
    window.open(
      "https://www.twitter.com/share?url=" +
      encodeURIComponent(fburl),
      "sharer",
      "left=0,top=0,width=626,height=436,personalbar=0,toolbar=0,scrollbars=yes,resizable=yes"
    );

  }
  getReferralBarChart() {
    if (localStorage.getItem("roleID") == 8) {
      let sta = this;

      let request = {
        end_point: "/recruiter-referral-statistics",
        token: localStorage.getItem("gs_token"),
      };

      sta.setState({ loader: "visible" }); ///loader on

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            let barData = resp.data;
            this.setState({ barData });
          }
        })
        .catch(() => {
          sta.setState({ loader: "hidden" }); ///loader off
        });
    } else if (localStorage.getItem("roleID") == 9) {
      let sta = this;
      let FromData = this.state.FromData;
      let start_date = FromData.start_date;
      let end_date = FromData.end_date;

      let request = {
        end_point: "/gwiaf-referral-statistics",
        token: localStorage.getItem("gs_token"),
      };

      sta.setState({ loader: "visible" }); ///loader on

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            let barData = resp.data;
            this.setState({ barData });
          }
        })
        .catch(() => {
          sta.setState({ loader: "hidden" }); ///loader off
        });
    }
  }

  top5Memberperformance() {
    let sta = this;
    let FromData = this.state.FromData;
    let start_date = FromData.start_date;
    let end_date = FromData.end_date;

    let request = {
      end_point: "/gwiaf-performance-statistics",
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    POST_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let topMember = resp.data;
          console.log("lll", topMember);
          this.setState({ topMember });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });
  }

  maskUnmask(){
    let sta =  this;
    
    var formdata = new FormData();
    formdata.append("app_name", "NEXGEN");
    formdata.append("showMaskURL", !this.state.showMaskURL);
    formdata.append("affiliate_code", this.state.affiliate_code);
    
    let request = {
        end_point : '/show-mask-url',
        token: localStorage.getItem("gs_token"),
        formdata : formdata
    };
    
    sta.setState({ loader: 'visible' })
    POST_API(request).then(resp=>{
      sta.setState({ loader: 'hidden' })
      if(resp.status == 'success'){
        alert(resp.message);
        setTimeout(function(){
          window.location.reload();
        },1500)
          
      }else{
        alert("Oops, there seems to be an error. Please contact the administrator for assistance");
        console.log("show mask unmask error",resp.message)
      }
    }).catch((ee)=>{
      sta.setState({ loader: 'hidden' })
      console.log('/code list ERR',ee)
    });
  }

  render() {
    //total joined
    let graphVal = this.state.barData.map((i) => ({
      label: i.month,
      Total_Joined: i.total_joined,
    }));

    const ds = new DataSet();
    const dv = ds.createView().source(graphVal);
    dv.transform({
      type: "fold",
      fields: ["Total_Joined"],
      key: "type",
      value: "Total_Joined", // value
    });

    //top 5 Member performents
    let TopMembergraphVal = this.state.topMember.map((i) => ({
      label: i.firstname + " " + i.lastname,
      Total_Joined: i.total_joined,
      Max_Sponsership: i.maxSponsership,
    }));

    const dtSet = new DataSet();
    const dtval = dtSet.createView().source(TopMembergraphVal);
    dtval.transform({
      type: "fold",
      fields: ["Max_Sponsership", "Total_Joined"],
      key: "type",
      value: "value", // value
    });

    let userDetails = this.state.userDetails;
    return (
      <div>
        <Loader loadmsg="please wait" visibility={this.state.loader} />
        {userDetails.hasOwnProperty("firstname") ? (<>


          <Container fluid className="dash_container">
            <Row>

              <Col md="12">
                URL shortener <input type="checkbox"  onChange={()=>{this.maskUnmask()}} checked={this.state.showMaskURL} />
              </Col>


              <Col md={12} className="pt-4 pb-4">

                {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                {(this.state.sponsorDetails != '') ? (<>

                  <div className="alert alert-info" role="alert">

                    <strong>Notice :  Welcome {JSON.parse(localStorage.getItem("gs_user")).firstname}, you have been sponsored by  <b>{this.state.sponsorDetails.name}</b> </strong>
                  </div>




                </>) : (<></>)}
                <div className="row dash_body justify-content-center">



                  <Col md={8}>
                    <Card>
                      {this.state.userRole == 9 ? (<>
                        <iframe title="vimeo-player" src="https://www.youtube.com/embed/zFr_buiJ5VI?si=Hg21p8O6x4tDOtSx" width="100%" height="400" frameBorder="0" allowFullScreen></iframe>
                      </>) : (<>
                        <iframe title="vimeo-player" src="https://www.youtube.com/embed/zFr_buiJ5VI?si=Hg21p8O6x4tDOtSx" width="100%" height="400" frameBorder="0" allowFullScreen></iframe>
                      </>)}
                    </Card>
                  </Col>





                  {
                      (localStorage.getItem('roleID') == 18 || localStorage.getItem('roleID') == 19) && 
                    <>
                      <Col md={12} className="mt-3 mb-2 mt-5">
                      <h5>Below is your referral link which you can share on social media and other platforms. When someone clicks on your link and becomes a paid user, you have the opportunity to earn a great commission right away.</h5>
                    </Col>
                     <Col md={this.state.userRole != 8 ? 6 : 12} className="mt-3 mb-3">
                        <div className="card p-3 redemption_code h-100">

                          <strong>Affiliate Referral link</strong>
                          {/* Jobseeker Link */}
                          <div className="r_code mb-3" id="affiliateId" data-value={this.state.affiliate_URL}><a href={this.state.affiliate_URL}>{this.state.affiliate_URL}</a></div>
                          <Col className="mt-1">
                            <Row className='justify-content-center'>
                              <ShareAffiliateLink {...this.props} handleReedemClear={() => this.handleReedemClear()} refcode={`<a href='` + this.state.affiliate_URL + "'>Click here</a>"} show={this.state.ShareModal4} close={() => this.handleClose4()} />
                              <Button variant="outline-primary mb-2" onClick={(e) => {
                                this.copyToClipboard(e, "affiliateId")
                              }} >Copy</Button>{' '}
                              <Button className="ml-3 mb-2" variant="outline-secondary" onClick={() => { this.shareOnFacebook("affiliateId") }}>Share on facebook</Button>{' '}
                              <Button className="ml-3 mb-2" variant="outline-secondary" onClick={() => { this.shareOnLinkedin("affiliateId") }}>Share on linkedin</Button>{' '}
                              <Button className="ml-3 mb-2" variant="outline-secondary" onClick={() => { this.shareOnTwitter("affiliateId") }}>Share on twitter</Button>{' '}
                              <Button className="ml-3 mb-2" variant="outline-primary" onClick={() => { this.handleClose4() }}>Share On Email</Button>
                              <div className="col-12 text-center"><p className="text-success"><b>{this.state.copySuccessAF}</b></p></div>
                            </Row>
                          </Col>
                        </div>
                      </Col>
                      </> 
                  }
                    
                  {this.state.products.map((item)=>{
                    let refferal_link = this.state.showMaskURL ? item.mask_url : item.refferal_link.replace('{affiliate_code}',this.state.affiliate_code);
                    let key_name = item.key;
                     return ( <Col md={this.state.userRole != 8 ? 6 : 12} className="mt-3 mb-3">
                      <div className="card p-3 redemption_code h-100">
                        <div className="text-center mb-3">
                          <img src={BASE_API_URL+'/storage/'+item.logo} className="w-50 mb-2" atr={item.name} /> <br />
                        <strong>{item.name} referral link</strong>
                        </div>
                        
                        {/* Jobseeker Link */}
                        <div className="r_code mb-3" id={item.key} data-value={refferal_link}><a href={refferal_link}>{refferal_link}</a></div>



                        <Col className="mt-1">
                          <Row className='justify-content-center'>
                            <ShareAffiliateLink {...this.props} affiliate_code={this.state.affiliate_code} handleReedemClear={() => this.handleReedemClear()} refcode={`<a href='` + refferal_link + "'>Click here</a>"} show={this.state.ShareModalNew[item.key]} close={() => this.handleCloseNew(item.key)} />
                            <Button variant="outline-primary mb-2" onClick={(e) => {
                              this.copyToClipboard(e, item.key)
                            }} >Copy</Button>{' '}

                            <Button className="ml-3 mb-2" variant="outline-secondary" onClick={() => { this.shareOnFacebook(item.key) }}>Share on facebook</Button>{' '}
                            <Button className="ml-3 mb-2" variant="outline-secondary" onClick={() => { this.shareOnLinkedin(item.key) }}>Share on linkedin</Button>{' '}
                            <Button className="ml-3 mb-2" variant="outline-secondary" onClick={() => { this.shareOnTwitter(item.key) }}>Share on twitter</Button>{' '}
                            <Button className="ml-3 mb-2" variant="outline-primary" onClick={() => { this.handleCloseNew(item.key) }}>Share On Email</Button>
                            &nbsp;&nbsp;
                            { item.knowledge_base_video && 
                            <Button variant="outline-primary mb-2" onClick={(e) => {
                              this.copyKnowledgeBase(e, item.key,item.knowledge_base_video)
                            }} >Promo Video</Button> }
                            {/* { (item.project_id && item.project_name !="") && 
                            <Button variant="outline-primary mb-2" onClick={(e) => {
                              this.copyKnowledgeBase(e, item.key,item.project_name)
                            }} >Copy Project name</Button> } */}

                            <div className="col-12 text-center"><p className="text-success"><b>{this.state.copySuccessMes[key_name]}</b></p></div>
                          </Row>
                        </Col>
                      </div>
                    </Col>)
                  })}
{/*                   
                  <NewProduct 
                  userRole = {this.state.userRole} 
                  copyToClipboard = {this.copyToClipboard}
                  handleReedemClear = {this.handleReedemClear}
                  shareOnFacebook = {this.shareOnFacebook}
                  shareOnLinkedin = {this.shareOnLinkedin}
                  shareOnTwitter = {this.shareOnTwitter}
                  copySuccessTR = {this.state.copySuccessTR}
                  copySuccessTR2 = {this.state.copySuccessTR2}
                  copySuccessYA = {this.state.copySuccessYA}
                  affiliate_code = {this.state.affiliate_code}
                  /> */}
<Row>

{this.state.products.map((item)=>{
 
   return ( 
   <>
    <Col md={4} className="mt-3 mb-3">

        <div className="card p-3 peope_ben_count d-flex justify-content-between align-items-center h-100 flex-column text-center">
          <strong className='mb-3'>Number of &nbsp;&nbsp;{item.name} Onboarded Using Your Referral Link.</strong>
          <div className="counter">{item.totl_count}</div>
          <a href={`#/affiliate/${item.name.toLowerCase().replaceAll(" ", "-")}/${item.id}`} className="sponsorshiplist">{item.name} List</a>
        </div>

      </Col>
  </>
  )
})}
</Row>
<Row>
{this.state.products.map((item)=>{
    return (<Col md={4} className="mt-3 mb-3">
      <div className="card p-3 peope_ben_count d-flex justify-content-between align-items-center h-100 flex-column text-center">

        <strong>Commission from {item.name} :</strong>
        <div style={{ fontSize: '40px', color: '#1aa3b9' }} className="h-80 d-flex align-items-center amt">{item.commission_type == 'percentage' ? item.commission_rate + '%' :'$'+item.commission_rate}</div>
        <p>You will receive a commission of {item.commission_type == 'percentage' ? item.commission_rate + '%' :'$'+item.commission_rate} off the {item.name} plan whenever a someone buys a plan.</p>
      </div>
    </Col>  )           
})}
      
</Row>
<Row>
                  <Col md={6} className="mt-3 mb-3">

                    <div className="card p-3 peope_ben_count d-flex justify-content-between align-items-center h-100 flex-column text-center">
                      <strong>Lifetime Commission Earned</strong>
                      <div style={{ fontSize: '40px', color: '#1aa3b9' }} className="h-80 d-flex align-items-center amt">${this.state.total_Commission}</div>
              </div>

                  </Col>
                  <Col md={6} className="mt-3 mb-3">

                    <div className="card p-3 peope_ben_count d-flex justify-content-between align-items-center h-100 flex-column">
                      <strong>Unpaid Commission</strong>
                      <div style={{ fontSize: '40px', color: '#1aa3b9' }} className="h-80 d-flex align-items-center amt">${this.state.due_commission}</div>                      
                    </div>
                </Col>
              </Row>


                  <Col md={12} className="mt-3 mb-3">
                    <Form className="tabform w-100" onSubmit={this.searchChart} >
                      <Row className='justify-content-end ml-0 mr-0 mb-3'>
                        <div className='search_block pl-3 pr-3 pt-2 pb-2 justify-content-end align-items-center'>
                          <Col sm="4" md="5" className='d-flex align-items-center'><span>From Date</span>
                            {/* <Form.Control
                          type="date"
                          className='ml-2'
                          name="start_date"
                          onChange={this.handaleChange}
                          validaterule={['required']}
                          validatemsg={['This field is required']}
                        /> */}
                            <DatePicker
                              className='pl-2'
                              selected={this.state.start_date}
                              name="start_date"
                              placeholderText="mm-dd-yyyy"
                              dateFormat="MM-dd-yyyy"
                              onChange={this.handleStartDateChange}
                            />
                            <br />
                            <p style={{ color: 'red' }}>{this.state.startDateErr}</p>
                            {/* <p style={{ color: 'red' }}>{this.state.FromDataError.start_date == 'false' ? '' : this.state.FromDataError.start_date}</p> */}
                          </Col>
                          <Col sm="4" md="5" className='d-flex align-items-center'> <span>To Date</span>
                            {/* <Form.Control
                          type="date"
                          className='ml-2'
                          name="end_date"
                          onChange={this.handaleChange}
                          validaterule={['required', 'lessThenFrom|' + this.state.FromData.start_date,]}
                          validatemsg={['This field is required', 'To date must be greater than from date']}
                        /> */}
                            <DatePicker
                              className='pl-2'
                              selected={this.state.end_date}
                              name="end_date"
                              placeholderText="mm-dd-yyyy"
                              dateFormat="MM-dd-yyyy"
                              onChange={this.handleEndDateChange}
                            />
                            <br />
                            <p style={{ color: 'red' }}>{this.state.endDateErr}</p>
                            {/* <p style={{ color: 'red' }}>{this.state.FromDataError.end_date == 'false' ? '' : this.state.FromDataError.end_date}</p> */}
                          </Col>
                          <Col className="search_btn" >
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn btn-primary btn-md">

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 256 256"
                                className='search_icon'
                              >
                                <defs>
                                </defs>
                                <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                  <path d="M 88.828 83.172 L 64.873 59.216 c 5.182 -6.443 8.002 -14.392 8.002 -22.779 c 0 -9.733 -3.79 -18.883 -10.673 -25.766 v 0 C 55.32 3.79 46.171 0 36.438 0 c -9.733 0 -18.883 3.79 -25.766 10.672 C 3.79 17.554 0 26.705 0 36.438 c 0 9.733 3.79 18.883 10.672 25.765 c 6.882 6.883 16.033 10.673 25.766 10.673 c 8.387 0 16.336 -2.82 22.779 -8.002 l 23.955 23.955 C 83.952 89.609 84.977 90 86 90 s 2.048 -0.391 2.828 -1.172 C 90.391 87.267 90.391 84.733 88.828 83.172 z M 16.329 56.546 C 10.958 51.175 8 44.034 8 36.438 c 0 -7.596 2.958 -14.737 8.329 -20.108 S 28.842 8 36.438 8 c 7.596 0 14.737 2.958 20.108 8.329 s 8.329 12.513 8.329 20.108 c 0 7.596 -2.958 14.737 -8.329 20.108 s -12.512 8.329 -20.108 8.329 C 28.842 64.875 21.7 61.917 16.329 56.546 z" />
                                </g>
                              </svg>
                            </Button>
                          </Col>
                        </div>
                      </Row>
                    </Form>

                    <div className="card p-3 mt-3 mb-3 peope_ben_count d-flex justify-content-center; align-items-center">
                      <strong>My Referral Statistics</strong>

                      <Chart height={300} autoFit data={dv.rows}>
                        <Interval position="label*Total_Joined" />
                        <Tooltip shared />
                      </Chart>
                      <br />
                      <span>
                        <strong style={{ color: "gray" }}><i className="fa-solid fa-arrow-up"></i>  X-Axis represents number of jobseekers on boarded,<i className="fa-solid fa-arrow-right ml-2"></i> Y-Axis represents past 12 months from now</strong>
                      </span>
                    </div>

                    {localStorage.getItem("roleID") == 9 && (
                      <div className="card p-3 mt-3 mb-3 peope_ben_count d-flex justify-content-center; align-items-center">
                        <strong>Performance Statistics of top 5 members</strong>

                        <Chart height={400} data={dtval.rows} autoFit>
                          <Legend />
                          <Coordinate actions={[["scale", 1, -1], ["transpose"]]} />
                          <Axis
                            name="label"
                            label={{
                              offset: 12,
                            }}
                          />
                          <Axis name="value" position={"right"} />
                          <Tooltip />
                          <Interval
                            position="label*value"
                            color={"type"}
                            adjust={[
                              {
                                type: "dodge",
                                marginRatio: 1 / 32,
                              },
                            ]}
                          />
                        </Chart>
                        <br />
                        <span>
                          <strong style={{ color: "gray" }}><i className="fa-solid fa-arrow-up"></i>  X-Axis represents number of jobseekers on boarded,<i className="fa-solid fa-arrow-right ml-2"></i> Y-Axis represents top 5 members from now</strong>
                        </span>
                      </div>)}
                  </Col>



                </div>
              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}
      </div>
    );
  }
}
