import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";

import Leftpanel from './Section/LeftPanel';
import DataTable from "react-data-table-component";
import { BASE_URL } from '../Config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';










export default class Notifications extends Component {

  constructor(props) {
    super(props)
    this.state = {
      notifications: {},
      FromData: {},
      FromDataError:
        {},
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/notification',
      help: '',
      hover: false,
      rowid: 0,
      readless: 0,
      totalRows: 0,
      offset: 1,
      limit: 10,

    };

    //Binding




  }


  async handledata(id) {
    console.log('handle data');
    await this.setState({ rowid: id, readless: id });
    console.log("rowid", this.state.rowid)
  }
  async handlelessdata(id) {
    console.log('handle data');
    await this.setState({ rowid: 0, readless: 0 });
    console.log("rowid", this.state.rowid)
  }

  handlePageChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request1 = {
      end_point: `/type-notification?type=all&offset=${page}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token")
    };


    GET_API(request1).then((resp) => {


      if (resp.status == 'success') {

        this.setState({ notifications: resp.data })
        console.log("notification data", resp.data);
      }

    }).catch(() => {



    })


  };



  handlePerRowsChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request1 = {
      end_point: `/type-notification?type=all&offset=${this.state.offset}&limit=${page}`,
      token: localStorage.getItem("gs_token")
    };

    GET_API(request1).then((resp) => {


      if (resp.status == 'success') {

        this.setState({ notifications: resp.data })
        console.log("notification data", resp.data);
        this.setState({ limit: page });
      }

    }).catch(() => {



    })
  };


  handleSearchChange = async (searchValue) => {
    let request1 = {
      end_point: `/type-notification?type=all&offset=${this.state.offset}&limit=${this.state.limit}&searchValue=${searchValue}`,
      token: localStorage.getItem("gs_token")
    };


    GET_API(request1).then((resp) => {


      if (resp.status == 'success') {

        this.setState({ notifications: resp.data })
        console.log("notification data", resp.data);
      }

    }).catch(() => {



    })
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }


    let request1 = {
      end_point: `/type-notification?type=all&offset=${this.state.offset}&limit=${this.state.limit}`,

      token: localStorage.getItem("gs_token")
    }


    GET_API(request1).then((resp) => {


      if (resp.status == 'success') {






        this.setState({ notifications: resp.data })
        this.setState({ totalRows: resp.row_count })
        console.log("notification data", resp.data);
      }

    }).catch(() => {



    })

    //help api
    let requestHelp = {
      end_point: '/help-details?key=sponsor_notification',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })





  }


  render() {
    let { FromData, notifications } = this.state

    const columns = [
      {
        name: "SL No",
        selector: (row, index) => row.sl_no,
        width: '90px',
        sortable: true,
        wrap: true,
      },
      {
        name: "Jobseeker Name",
        cell: row => <table cellPadding="10px"><td>{(row.name || "") + " " + (row.lastname || "")}</td></table>,
        // sortable: true
      },
      {
        name: "Jobseeker Email",
        cell: row => <table cellPadding="10px"><td>{row.email}</td></table>,
        // sortable: true
      },
      {
        name: "Subject",
        selector: row => row.feedback_subject,
        sortable: true
      },
      {
        name: "Message",
        cell: row => {
          return (
            <div>{this.state.rowid != row.id ? (row.feedback_body.substring(0, 100)) : (row.feedback_body)}{this.state.readless != row.id ? (<Nav.Link onClick={() => { this.handledata(row.id); console.log('cell row id', this.state.rowid) }} ><b>...Read more</b></Nav.Link>) : (<Nav.Link onClick={() => { this.handlelessdata(row.id); console.log('cell row id', this.state.rowid) }} ><b>...Read less</b></Nav.Link>)}</div>
          )
        },

        sortable: true
      },
      {
        name: "Time",
        // selector: row => row.created_at,
        selector: row => moment(row.created_at).format('MM-DD-YYYY'),
        sortable: true
      },


    ];

    return (
      <div>
        {FromData != null ? (<>


          <Container fluid className="dash_container">
            <Row>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12} className="pt-4 pb-4">
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                <div className="tabpanelOneForm">

                  <h3>Notifications</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.help
                    }}></p>


                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

             
                  <input
                      className="form-control float-right"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => this.handleSearchChange(e.target.value)}
                      style={{maxWidth: '15rem'}}
                  />


                  <DataTable
                    title=""
                    columns={columns}
                    data={notifications}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                    paginationServer
                    paginationTotalRows={this.state.totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                  />


                </div>



              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}


      </div>




    );
  }
}

