import React, { useEffect, useState } from 'react'
import { Card,Container, Row, Col, Pagination } from "react-bootstrap";
import {POST_API, Loader } from "../Helpers/Helpers";


const KnowledgeBaseVideo = (props) => {

    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
   
    
    const fetchNexgenProducts = ()=>{
        var formdata = new FormData();
        formdata.append("app_name", "NEXGEN");
       
        let request = {
          end_point : '/nexgen-products',
          token: localStorage.getItem("gs_token"),
          formdata : formdata
      };
      
      setLoading(true)
      POST_API(request).then(resp=>{
        setLoading(false)
            if(resp.status == 'success')
            {
                setRecords(resp.data.products);
            }else{
                setRecords([]);
            }
        }).catch((ee)=>{
            setLoading(false)
          console.log('/code list ERR',ee)
        });
      }

    useEffect(() => {
        fetchNexgenProducts();
    }, [])

    return (
    <div className='pt-5 pb-5 data_table_gwi'>
         <Container fluid className="dash_container text-center">

          <Row>
              <Col md={12}>
              <Loader loadmsg="please wait" visibility={loading ? "visible" : "hidden"} />
                  
              </Col>
                <Col md={6}>
                <h2 className='jobseeker_heading_hr'> Knowledge base Videos</h2>  
                </Col>
                <Col md={2}></Col>
                
            </Row>

                <Row>

                {records.length > 0 && 
    records
        .filter((item) => item.knowledge_base_video)
        .map((item, i) => (
            <Col md={6} className="p-3" key={i}>
                <Card>
                    <Card.Title>{item.name}</Card.Title>
                    <iframe title="vimeo-player" src={item.knowledge_base_video} width="100%" height="400" frameBorder="0" allowFullScreen></iframe>                                  
                </Card>
            </Col>
        ))
}

           
                  
                 </Row>

          </Container>  
           </div>
    )
}

export default KnowledgeBaseVideo;