import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button,Modal,Form } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import { BASE_API_URL } from '../Config';
import DataTable from "react-data-table-component";
import moment from 'moment';

function ManageProjects(props) {
  const [ContactGroupModal, setContactGroupModal] = useState(false);
  const [loader, setLoader] = useState('hidden');
  const [products, setProducts] = useState({});
  const [filtered, setFiltered] = useState({});
  const [type, setType] = useState({});
  const [product, setProduct] = useState({});
  const [msg, setMsg] = useState("");
  
  // Columns definition
  const columns = [
    {
      name: "SL. No.",
      selector: (row, index) =>  index + 1,
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Project Name",
      selector: row => row.name,
    },
    {
      name: "Organization",
      selector: row => <><img src={row.organization.photo} className='img-fluid' alt="Logo"/> {row.organization.name}</>,
    },
    {
      name: "Action",
      selector: row => localStorage.getItem("roleID") == 9 ? <button className='btn btn-info' onClick={() => {setType("Update");setProduct(row);handlemodalShow()}}> <i className='fa fa-pencil'></i></button> : ""
    }
  ];
  const handleSearch = (data) => {
    let val = data.target.value;
    let f ;
    if(val == ""){
        f = products;
    }else{
         f = products.filter(
            (customer) =>
              customer.name.toLowerCase().includes(val.toLowerCase())
          );
    }
    setFiltered(f);
  };


  const handlemodalShow = () => {
      setContactGroupModal(!ContactGroupModal);
  };


  const getProducts = ()=>{
        let request = {
            end_point : '/gcn-projects',
            token: localStorage.getItem("gs_token")
        };

        POST_API(request).then(resp=>{
               if(resp.status == 'success')
               {
                if(resp.products == null){
                  setProducts([]);
                  setFiltered([]);
                }else{
                    setProducts(resp.products);
                    setFiltered(resp.products);
                }
               }else{
                setProducts([]);
                setFiltered([]);
               }
        }).catch((ee)=>{
            console.log('/code list ERR',ee)
        });
    }

  useEffect(() => {
    window.scrollTo(0, 0);
    getProducts();
  }, []);

  return (
    <div>
      <Container fluid className="dash_container">
        <Row>
          <Col md={12}>
            <Loader loadmsg="please wait" visibility={loader}/>
            <div className="tabpanelOneForm pt-3">
                {msg !== '' && <FlashMess m={msg} mtype={'success'} />}
              <h3>Bizlink Project List
              {
                localStorage.getItem("roleID") == 9 && 
                <>
                  {ContactGroupModal && <AddNewProduct type={type} msg={""} product={product} show={ContactGroupModal} close={() => handlemodalShow()}/> }
                  <a href="javascript:void(0)" onClick={() => {setType("Create");setProduct({});handlemodalShow()}} className='btn btn-primary ghost_button float-right'>Create</a>
                </>
              }
              </h3>

              
              <div className="table_search" >   
                Search : <input type='text' placeholder='Search' onChange={handleSearch} name="search_val"  />
              </div>
              <DataTable
                title=""
                columns={columns}
                data={filtered}
                defaultSortField="title"
                pagination={true}
                NoDataComponent={"No data"}
                noHeader={true}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ManageProjects;

function AddNewProduct(props) {
    const [NFormData, setNFormData] = useState({
        name: props.product?.name ?? "",
        description: props.product?.description ?? ""
    });
    const [NFormDataError, setNFormDataError] = useState({
        name: props.product?.name ? "" : "false",
        description: props.product?.description ? "" : "false",
    });
    
    const [msg, setMsg] = useState(props.msg ?? "");
    const [msgType, setMsgType] = useState('');
    const [loader, setLoader] = useState('hidden');
    
    const handaleChange = (data) => {
        let nNFormData = { ...NFormData }
        let newNFormDataError = { ...NFormDataError };
        setNFormData({...nNFormData, [data.target.name]: data.target.value});
        if (data.target.value !== "") {
            setNFormDataError({...newNFormDataError, [data.target.name]: ""});
        } else {
            setNFormDataError({...newNFormDataError, [data.target.name]: "false"});
        }
    };
  

    const validateFormNew = (errors) => {
        let valid = true;
        let newFromDataError = { ...NFormDataError };

        for (const [key, value] of Object.entries(errors)) {
          if (value.length > 0) {
            if (value === 'false') {
              newFromDataError[key] = 'This field is required';
              setNFormDataError(newFromDataError);
            }
            valid = false;
          }
        }

        return valid;
      };

    const submitForm = (e) => {
      e.preventDefault();

      if(validateFormNew(NFormDataError)){
        try {
        
            setLoader('visible');
            let Nformdata = new FormData();
            Nformdata.append('description', NFormData.description);
            Nformdata.append('name', NFormData.name);
            let end_point = "/create-gcn-project";
            if(Object.keys(props.product).length !== 0){
                Nformdata.append('id', props.product._id);
                end_point = "/edit-gcn-project";
            }
            
            let request = {
                end_point: end_point,
                formdata: Nformdata,
                token: localStorage.getItem('gs_token'),
            };

            POST_API(request)
                .then((resp) => {
                setLoader('hidden');
                if (resp.status == 'success') {
                    setMsg(resp.message);
                    setMsgType('success');
                    setTimeout(() => {
                    window.location.reload();
                    }, 3500);
                } else {
                    setMsg(resp.message);
                    setMsgType('danger');
                }
                })
                .catch((ee) => {
                  setLoader('hidden');
                 console.log('/addProjectERR', ee);
                });
        } catch (err) {
            setLoader('hidden');
            console.log("error",err)
            setMsg('Oops there is some error , please contact to admin ');
            setMsgType('danger');
        }
        }
    };
  
    return (
      <div id="PaymentForm">
        <Loader loadmsg="please wait" visibility={loader}/>
        <Modal
          show={props.show}
          backdrop="static"
          keyboard={false}
          onHide={props.close}
          id="modal"
        >
          <Form className="tabform" onSubmit={submitForm}>
            <Modal.Header closeButton>
              <Modal.Title>{props.type} Project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="m-0 mt-4 mb-4">
                <Col md={12} className="pl-0">
                  {msg !== '' && <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />}
                </Col>   
                <Col md={12} className="pl-0">
                    <Form.Group>
                      <Form.Label>Project Name <span className="text-danger">*</span></Form.Label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          name = "name"
                          defaultValue={NFormData.name}
                          validaterule={['required']}
                          validatemsg={['This field is required']}
                          onChange={handaleChange}
                        />
                      </div>

                      <span className="text-danger">{NFormDataError.name !== "false" && NFormDataError.name}</span>
                    </Form.Group>
                  </Col>
                  
                  <Col md={12} className="pl-0">
                    <Form.Group>
                      <Form.Label>Description <span className="text-danger">*</span></Form.Label>
                      <div className="">
                        <textarea
                          className="form-control"
                          name = "description"
                          defaultValue={NFormData.description}
                          validaterule={['required']}
                          validatemsg={['This field is required']}
                          onChange={handaleChange}
                        />
                      </div>
                      <span className="text-danger">{NFormDataError.description !== "false" && NFormDataError.description}</span>
                    </Form.Group>
                  </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit" className="btn btn-primary btn-md tabformbutton">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
