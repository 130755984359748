import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../../Helpers/Helpers";
import validation from '../../Validate/validator';
import Leftpanel from '../Section/LeftPanel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye,faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

class CreateMember extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        list:{},
                        FromData: {
                            memberType : '',
                            member_contact_no : '',
                            member_firstname : '',
                            member_lastname : '',
                            member_email : '',
                            member_password : '',
                             max_user_share : '',
                          
                        },
                        FromDataError :
                                        {
                                            memberType : 'false',
                                            member_contact_no : 'false',
                                            member_firstname : 'false',
                                            member_lastname : 'false',
                                            member_email : 'false',
                                            member_password : 'false',
                                            max_user_share: '',
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/members',
                        inputType:'password',

                    }



                    ///bind
                    this.handaleChange = this.handaleChange.bind(this)
                    this.handaleSubmit = this.handaleSubmit.bind(this)
                    this.toggleEye=this.toggleEye.bind(this)
    }


  handalePhoneNoChange(data) {
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    let value = '';
    FromData['member_contact_no'] = data;
    this.setState({ FromData }, () => {

    })

    if (FromData.member_contact_no != '') {
      var valid_obj = {
        value: value,
        rules: 'required',
        message: 'This field is required'
      }
      validation(valid_obj).then((error) => {
        FromDataError['member_contact_no'] = true
        this.setState({ FromDataError }) //from error state
      })
    }

  }
      
    validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;
  
        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {
  
            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
  };
  




    handaleChange(data)
  {

    if(data.target.type != "number" && data.target.type != "email"){
      const pointer = data.target.selectionStart;
      const element = data.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
    }

      let sta = this;
      let {FromDataError,FromData} = sta.state;
      

      let name = data.target.name;
      let value =  data.target.value;
      FromData[name] = value;
    
      
     var valid_obj = {
        value:value,
        rules:data.target.getAttribute("validaterule"),
        message:data.target.getAttribute("validatemsg")
      }


    
      validation(valid_obj).then((error)=>{

       
       
        FromDataError[name] = error
        this.setState({FromDataError})
       

        this.setState({FromData},()=>{

         // console.log(FromDataError)
          
        })
      })
    
       

       
      
  }

  handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let {FromDataError,FromData} = this.state;


        if(this.validateForm(FromDataError))
        {
          sta.setState({loader:'visible'})///loader on

                    var formdata = new FormData();

                    if( FromData.memberType == 'Employee')
                    {
                    formdata.append("roleID", 11);
               

                    }
                    else{
                    formdata.append("roleID", 12);
                  


                    }
                 
                   

                    formdata.append("firstname", FromData.member_firstname);
                    formdata.append("lastname", FromData.member_lastname);
                    formdata.append("email", FromData.member_email);
                    formdata.append("password", FromData.member_password);
                    formdata.append("contact_no", FromData.member_contact_no);
                    formdata.append("employerID",JSON.parse(localStorage.getItem("gs_user")).userID);
                    formdata.append("max_user_share",FromData.max_user_share);
                    formdata.append("app_name", "NEXGEN");

 


                        let request = {
                                    end_point : '/create-affiliate-member',
                                    formdata:formdata,
                                    token: localStorage.getItem("gs_token")
                                    };


                        POST_API(request).then((resp)=>{
                         sta.setState({loader:'hidden'})///loader on


                            if(resp.status=='success')
                            {
                                
                                


                                sta.props.history.push({
                                    pathname: '/members-affiliate',
                                    state: { Msg:'User Created Successfully!',Msgtype:'succcess'}
                                })
                                
                                
                                
                            }
                            else{
                                //console.log(resp.message.password.join())
                                sta.setState({Msg:resp.message.email.join(),MsgType:'danger'})
                            }

                        }).catch(()=>{
                          sta.setState({loader:'hidden'})///loader on
                          sta.setState({Msg:'Something Went Wrong,Please try again later.',MsgType:'danger'})
                       


                        })


        }
        else
        {
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
        }


       

    }

    toggleEye()
     {
      var temp = document.getElementById("password");
            if (temp.type === "password") {
                this.setState({inputType:'text'});
               
            }
            else 
            {
              this.setState({inputType:'password'});
                
            }
     }






    render() {
      const eye = <FontAwesomeIcon icon={faEye} />;
      const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
        let {FromData} = this.state
        return (
            <div>
                 {'a'=='a'? (<>
            

            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm pt-3">

            <h3>Create Member</h3>
                {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
                
                


                <div className="tabpanelOneForm">
              <Form className="tabform" onSubmit={this.handaleSubmit}>
                  
                    <Row className="m-0 mt-4 mb-4">
                            <Col md={12} className="">
                             
                              <Form.Row>

                              <Form.Group as={Col} controlId="formGridState" >
                                    <Form.Label>Member Type <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                     validaterule={['required']}
                                     validatemsg={['This field is required']}
                                     as="select" defaultValue="Type" name="memberType" onChange={this.handaleChange}>
                                    <option value="">select</option>
                                    {/* <option>Manager</option> */}
                                    <option>Employee</option>
                                    </Form.Control>
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.memberType=='false'?'': this.state.FromDataError.memberType}</p>

                                </Form.Group>

                             
                                </Form.Row>
                                <Form.Row>

                                <Form.Group controlId="formBasicEmailaff" as={Col}>
                                  <Form.Label>First Name <span className='text-danger'>*</span></Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="member_firstname"
                                    value={FromData.member_firstname}
                                    onChange={this.handaleChange}
                                    placeholder="Enter  first name"
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_firstname=='false'?'': this.state.FromDataError.member_firstname}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmailaff1" as={Col}>
                                  <Form.Label>Last Name <span className='text-danger'>*</span></Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="member_lastname"
                                    value={FromData.member_lastname}
                                    onChange={this.handaleChange}
                                    placeholder="Enter  last name"
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_lastname=='false'?'': this.state.FromDataError.member_lastname}</p>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group controlId="formBasicEmailaff2" as={Col}>
                                  <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="member_email"
                                    value={FromData.member_email}
                                    onChange={this.handaleChange}
                                    placeholder="Enter  email"
                                    validaterule={['required','isEmail']}
                                    validatemsg={['This field is required','Invalid Email']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_email=='false'?'': this.state.FromDataError.member_email}</p>
                                </Form.Group>

                                {/* <Form.Group controlId="formBasicEmailaff3" as={Col}> */}
                                  <Form.Label>Phone <span className='text-danger'>*</span></Form.Label>
                                  <PhoneInput
                                    maxLength= {16}
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="US"
                                    value={FromData.member_contact_no}
                                    name="member_contact_no"
                                    onChange={this.handalePhoneNoChange.bind(this)}
                                    validaterule={['required', 'phone']}
                                    validatemsg={['This field is required', 'Invalid Phone number']}
                                  />

                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_contact_no=='false'?'': this.state.FromDataError.member_contact_no}</p>
                                {/* </Form.Group> */}
                              </Form.Row>
                              <Form.Row>
                              <Form.Group controlId="formBasicEmailaff" as={Col}>
                                  <Form.Label>Password <span className='text-danger'>*</span></Form.Label>
                                  <Form.Control
                                    type={this.state.inputType}
                                    name="member_password"
                                    id='password'
                                    onChange={this.handaleChange}
                                    value={FromData.member_password}
                                    placeholder="Enter Password"
                                    validaterule={['required','password']}
                                    validatemsg={['This field is required','password should be 6 charecter']}
                                  />
                                    <i  style={{  float: 'right', marginTop: '-35px', marginRight:'8px', position: 'relative', zIndex: 1, cursor:'pointer'}} onClick={this.toggleEye}>{this.state.inputType=='text'?eyeSlash:eye}</i>  
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_password=='false'?'': this.state.FromDataError.member_password}</p>
                                </Form.Group>

                                
                                {/* <Form.Group controlId="formBasicEmailaff" as={Col}>
                                  <Form.Label>Max User Share</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="max_user_share"
                                    value={FromData.max_user_share}
                                    onChange={this.handaleChange}
                                    placeholder="Enter max user share"
                                    validaterule={['required', "notNegative"]}
                                    validatemsg={['This field is required', "invalid max user share"]}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.max_user_share=='false'?'': this.state.FromDataError.max_user_share}</p>
                                </Form.Group> */}

                              </Form.Row>
                            </Col>
                            <Col md={12} >
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn btn-primary btn-md tabformbutton"
                                disabled={this.state.loader!='hidden'? true : false}
                              >
                                Submit
                              </Button>
                            </Col>
                         
                        
                    </Row>
                    </Form>
                      
            </div>
               
                   
            </div>
            </Col>     
          </Row>
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
        );
    }
}

export default CreateMember;