import React, { useState, useEffect } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { FlashMess, POST_API, Loader } from '../Helpers/Helpers';
import validation from '../Validate/validator';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';

import { Elements,CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {STRIPE_KEY} from "../Config";
import { loadStripe } from "@stripe/stripe-js";
import ReactPlayer from 'react-player'
const stripePromise = loadStripe(STRIPE_KEY);
const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const MyTools = () => {
  const [paid,setPaid] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedTool, setSelectedTool] = useState('');
  const [loader, setLoader] = useState("hidden");
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [showProduct, setShowProduct] = useState(false);
  const [product, setProduct] = useState({});

  useEffect(() => {
    checkSubscription();
  }, []);

  const showProductModal = (p,logo)=>{
    if(p === 'Biz-Link'){
      let product = {
        logo : logo,
        name : p,
        tag_line : "Biz-Link Tool Features:",
        bullets : <><li>Personalized AI-generated LinkedIn InMails.</li><li>Engages through likes, shares, comments of LinkedIn posts</li><li>Hosts, promotes LinkedIn webinars.</li><li>Provides detailed engagement analytics.</li></>,
        video : "https://www.youtube.com/embed/ROReVa_9xH8"
      };
      setProduct(product);
      setShowProduct(true);
    }else if(p === 'Biz-Chirp'){
      let product = {
        logo : logo,
        name : p,
        tag_line : "Biz-Chirp Tool Features:",
        bullets : <><li>Personalizes Twitter Direct Messages.</li><li>Increases engagement via retweeting, replying.</li><li>Facilitates targeting with smart algorithms.</li><li>Tracks detailed Twitter interactions.</li></>,
        video : "https://www.youtube.com/embed/x1WBh8p1IKw"
      };
      setProduct(product);
      setShowProduct(true);
    }
  }
  const checkSubscription = () => {
    var formdata = new FormData();
    formdata.append('app_name', 'NEXGEN');

    let request = {
      end_point: '/check-subscription',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    setLoader('visible');
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          setPaid(true);
        } else {
          setPaid(false);
        }
      })
      .catch((e) => {
        setLoader('hidden');
        setMsg("Subscription : " +e);
        setMsgType('danger');

      });
}


const generateToken = (tool) => {
  var formdata = new FormData();
  formdata.append('app_name', 'NEXGEN');
  formdata.append('tool', tool);

  let request = {
    end_point: '/generate-token',
    token: localStorage.getItem("gs_token"),
    formdata: formdata,
  };
  setLoader('visible');
  POST_API(request)
    .then((resp) => {
      setLoader('hidden');
      if (resp.status === 'success') {
        window.open(resp.url, '_blank');
      } else {
        setMsg(resp.message);
        setMsgType('danger');
      }
  })
  .catch((e) => {
    setLoader('hidden');
    setMsg("Token Generation Error "+e);
    setMsgType('danger');
  });
};

  const handleClick = async (tool) => {
    if (!paid) {
      setShowModal(true);
      setSelectedTool(tool);
    } else {
      switch (tool) {
        case 'Biz-Link': case 'Biz-Chirp':
          generateToken(tool);
        break;
        default:
            setMsg("invalid Tool");
            setMsgType('danger');
        break;
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTool('');
  };

  const handlePayment = async () => {
    setShowModal(false);
    setPaymentModal(true);
  };

  return (
    <Container fluid className="my-3">
<Modal
    show={showProduct}
    onHide={() => { setShowProduct(false) }}
    dialogClassName="modal-90w modal_all_ex_bun"
    aria-labelledby="example-custom-modal-styling-title"
    className='jobseeker_modal'
    centered
    >
      <div className="modal_overlay"></div>
      <Modal.Body>
        <div className='close_btn' onClick={() => { setShowProduct(false) }}>x</div>
        <Row>
          <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">

            <div className="modal_heading" style={{ backgroundColor: '#4E54C8' }}>
              <img src={product.logo} className="modal_service_img" />
              <h2 className="modal-content-h2">{product.name}</h2>
            </div>
            <p>{product.tag_line}</p>
            <ul>{product.bullets}</ul>
          </Col>
          <Col sm={12} md={6} lg={6} className="videoframe">

            {/* <div className="videoframe_img">
              <img src={product.logo} className="img-fluid w-100" />
            </div> */}

            <div className="video" style={{"position":"unset"}}>
              <ReactPlayer
                className='react-player'
                url={product.video}
                width='100%'
                height='100%'
              />
        </div>
      </Col>
    </Row>
  </Modal.Body>
</Modal>

      <Loader loadmsg="please wait" visibility={loader} />
      <Row>
        <Col md={12}>
          <h3>My Tools</h3>

          {msg !== '' ? (
    <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
  ) : (
    <></>
  )}

                  <Row>
                    <Col md={6} className="mt-3 mb-3 text-center">
                        <div className="card p-3 redemption_code h-100">
                          <div className='text-center'><img src="https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png" className='w-25'/></div>
                          <br />
                         <strong>Biz-Link</strong>
                          <Col className="mt-1">
                            <Row className='justify-content-center'>
                               <Button variant="outline-primary mb-2" onClick={() => handleClick('Biz-Link')}>Access Now</Button> &nbsp;&nbsp;&nbsp;
                               <Button variant="outline-primary mb-2" onClick={() => showProductModal('Biz-Link',"https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png")}>View Description</Button>
                            </Row>
                          </Col>
                        </div>
                      </Col>
                      <Col md={6} className="mt-3 mb-3 text-center">
                        <div className="card p-3 redemption_code h-100">
                        <div className='text-center'><img src="https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png" className='w-25'/></div>
                          <br />
                         <strong>Biz-Chirp</strong>
                          <Col className="mt-1">
                            <Row className='justify-content-center'>
                              <Button variant="outline-primary mb-2" onClick={() => handleClick('Biz-Chirp')}>Access Now</Button> &nbsp;&nbsp;&nbsp;
                              <Button variant="outline-primary mb-2" onClick={() => showProductModal('Biz-Chirp',"https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png")}>View Description</Button> 
                            </Row>
                          </Col>   
                        </div>
                      </Col>
                  </Row> 
        </Col>
        <PaymentTransaction />
        
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Access Denied</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You need to pay to access <strong>{selectedTool}</strong>. Please proceed to payment.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePayment}>Pay Now</Button>
        </Modal.Footer>
      </Modal>

      {paymentModal && <Payment price={9.99} show={true} close={()=>{setPaymentModal(false)}}/>}
    </Container>
  );
};

export default MyTools;

const PaymentTransaction = ()=>{
  const [subcriptionList, setSubcriptionList] = useState([]);
  const [loader,setLoader] = useState("hidden");
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');


  const getSubscription = ()=>{
      var formdata = new FormData();
      formdata.append('app_name', 'NEXGEN');

      let request = {
        end_point: '/get-affiliate-subscription',
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
      };
      setLoader('visible');
      POST_API(request)
        .then((resp) => {
          setLoader('hidden')
          if (resp.status === 'success') {
            setSubcriptionList(resp.data)
          } else {
            setSubcriptionList([]);
          }
        })
        .catch((e) => {
          setLoader('hidden')
          setMsg(e);
          setMsgType('danger');          
        });
  }

  useEffect(() => {
    getSubscription();
  }, []);

  const CanCancelSubsciption = (subscriptionId)=>{
    if(!subscriptionId || subscriptionId === "" || subscriptionId === undefined){
      return;
    }

    var formdata = new FormData();
      formdata.append('app_name', 'NEXGEN');
      formdata.append('subscriptionId', subscriptionId);
      let request = {
        end_point: '/can-cancel-subscription',
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
      };
      setLoader('visible');
      let result = POST_API(request)
      .then((resp) => {
        setLoader('hidden');

        if(resp.status != "success"){
          setMsg(resp.message);
          setMsgType('danger');
          return;
        }
    
        let canCancel = true;
        if(resp.message == "SHOW_ALERT"){
          if(!window.confirm("Subscription is in the middle of the month, if you will cancel subscription now you won't get any refund")){
            canCancel = false
          }
        }
        if(canCancel){
          cancelSubsciption(subscriptionId);
        }
        
      })
      .catch((e) => {
        setLoader('hidden');
        console.log("Check Subscription error",e)
        return false;
      });

  }

  const cancelSubsciption = (subscriptionId)=>{
    if(!subscriptionId || subscriptionId === "" || subscriptionId === undefined){
      return;
    }
    var formdata = new FormData();
      formdata.append('app_name', 'NEXGEN');
      formdata.append('subscriptionId', subscriptionId);
      let request = {
        end_point: '/cancel-subscription',
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
      };
      setLoader('visible');
      POST_API(request)
      .then((resp) => {
        setLoader('hidden')
        setMsg(resp.message);
        if (resp.status === 'success') {
          setMsgType('succcess');
          setTimeout(function(){
            window.location.reload();
          },1500)
        }
      })
      .catch((e) => {
        setLoader('hidden')
        setMsg(e);
        setMsgType('danger');
        console.log("Payment Error",e)
      });
  }

  return <>
    <Loader loadmsg="please wait" visibility={loader} />
    <Col md={12}>

    {msg !== '' ? (
      <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
    ) : (
      <></>
    )}
    
        {subcriptionList.length > 0 && <>
          <h3>Payment Transaction</h3>

          <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Payment Date</th>
                  <th>Amount</th>
                  <th>Subscription ID</th>
                  <th>Is Recurring</th>
                  <th>Is Cancelled ?</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {subcriptionList.map((item,index)=>{
                     return (  
                          <tr>
                              <th>{++index}</th>
                              <th>{item.payment_date}</th>
                              <th>${item.amount}</th>
                              <th>{item.subscriptionId}</th>
                              <th>Yes</th>
                              <th>{item.cancelled_at ? "Cancelled AT "+item.cancelled_at : "" }</th>
                              <th>{item.cancelled_at ? "" : <Button onClick={()=>{cancelSubsciption(item.subscriptionId)}}>Cancel Subscription</Button> }</th>
                            </tr>
                                );
                })}

              </tbody>
          </table>
        </> }
        </Col>

  </>
}

const Payment = (props) => {
  const [amount, setAmount] = useState('');
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [loader, setLoader] = useState('hidden');

  useEffect(() => {
    setAmount(props.price);
  }, [props]);

  const handleFinalSubmit = (result) => {
   if(result.error){
    setMsg(result.error.message);
    setMsgType('danger');
    return;
   }
   setMsg("");
   setMsgType('danger');
    setLoader('visible');
    var formdata = new FormData();
    formdata.append('card_details', JSON.stringify(result));
    formdata.append('amount', amount);
    formdata.append('app_name', 'NEXGEN');
    formdata.append('gs_token', localStorage.getItem('gs_token'));
    let request = {
      end_point: '/charge-affiliate',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          console.log('respsssspp', resp);
          setMsg(resp.message);
          setMsgType('succcess');
          setTimeout(function(){
           window.location.reload();
          },1500);
        } else {
          setMsg(resp.message);
          setMsgType('danger');
        }
      })
      .catch((e) => {
        console.log("Payment Error",e)
        setLoader('hidden');
        setMsg('Something Went Wrong,Please try again later.');
        setMsgType('danger');
      });
}

  return (
    <div id="PaymentForm">
      <Modal
        show={props.show}
        backdrop="static"
        keyboard={false}
        onHide={props.close}
        id="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Loader loadmsg="please wait" visibility={loader} />
            {msg && <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />}
            <Elements stripe={stripePromise}>
              <CheckoutForm amount={amount} handleFinalSubmit={handleFinalSubmit} setLoader={setLoader} close={props.close} />
            </Elements>
        </Modal.Body>
      </Modal>
    </div>
    
  );
};

const CheckoutForm = (props) => {

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    props.setLoader('visible');
    stripe.createToken(elements.getElement(CardElement)).then((result) => {
      props.setLoader('hidden');
      console.log("ffffffffffffffffffffffffsubtttt", result);
      props.handleFinalSubmit(result)
    });
    props.setLoader('hidden');
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardElement />

      <Form.Row className='mt-3'>
          <Form.Group controlId="formBasicCardNo" className="pr-0" as={Col}>
            <Form.Label>Pay Amount (<strong>${props.amount}</strong>)</Form.Label>
          </Form.Group>
        </Form.Row>
        <Modal.Footer>
      <Button
              variant="primary"
              onClick={props.close}
              className="btn btn-primary btn-md tabformbutton mr-1"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              type="submit"
              className="btn btn-primary btn-md tabformbutton"
            >
              Pay
            </Button>
            </Modal.Footer>
    </form>
  );
};
