import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, DropdownButton, Dropdown, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';
import DataTable from "react-data-table-component";
import { BASE_URL } from '../Config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MemberCommissionViewModal from './Section/MemberCommissionViewModal'
import moment from "moment";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';


export default class AffiliateEmployeeCommision extends Component {

  constructor(props) {
    super(props)
    this.state = {

      FromData: {

        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment(new Date()).format("YYYY-MM-DD"),
        allpaymentId: [],

      },
      FromDataError:
      {
        start_date: 'false',
        end_date: 'false',

      },
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/employee-commission',
      memberViewModal: false,
      type : "GBTW",
      member_id: 0,
      payment_start_date: "",
      payment_end_date: "",
      allCommissionList: '',
      help: '',
      errMsg: ''
    };

    this.searchCommission = this.searchCommission.bind(this)
    this.handaleChange = this.handaleChange.bind(this)

    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    //Binding




  }

  handleClose(member_id = 0,type = "") {
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    let start_date = FromData.start_date
    let end_date = FromData.end_date

    let payment_start_date = start_date
    let payment_end_date = end_date
    sta.setState({ payment_start_date })
    sta.setState({ payment_end_date })

    if (this.state.memberViewModal) {
      this.setState({ memberViewModal: false })
    }
    else {
      this.setState({ member_id: member_id, memberViewModal: true })

    }
  }


  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
        console.log('loop', valid)
      }

    }


    console.log('funct', valid)

    return valid;
  };


  handaleChange(data) {

    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = '';
    let FromData = this.state.FromData;


    value = data.target.value;
    FromData[name] = value;
    this.setState({ FromData }, () => {

    })

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg")
    }

    validation(valid_obj).then((error) => {
      FromDataError[name] = error
      this.setState({ FromDataError }) //from error state
    })


  }


  searchCommission(e) {

    e.preventDefault();
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;

    if (this.validateForm(FromDataError)) {
      var formdata = new FormData();
      let start_date = FromData.start_date
      let end_date = FromData.end_date

      let payment_start_date = start_date
      let payment_end_date = end_date
      sta.setState({ payment_start_date })
      sta.setState({ payment_end_date })

      sta.setState({ loader: 'visible' })///loader on

      let requestsearch = {
        end_point: '/employee-commission-list?to_date=' + start_date + '&from_date=' + end_date,
        token: localStorage.getItem("gs_token")

      };

      GET_API(requestsearch).then(resp3 => {

        sta.setState({ loader: 'hidden' })///loader off


        if (resp3.status == 'success') {

          let allCommissionList = resp3.data

          sta.setState({ allCommissionList })


        }
      }).catch((ee) => {

        sta.setState({ loader: 'hidden' })///loader off

        console.log('/employee listERR', ee)
      });




    }


  }



  columns = [
    {
      name: "SL. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px",
      wrap: true,
    },

    {
      name: "Source Type",
      selector: (row) =>row.type ? "Nexgen" : "GBTW" ,
      sortable: false,
      width: "100px",
      wrap: true,
    },
    {
      name: "Name",
      selector: row => (row.firstname || '') + ' ' + (row.lastname || ''),
      sortable: true,
      wrap: true,
      width: '200px',
    },
    {
      name: "Email",
      // selector: row => row.email ,
      selector: (row) => { return <a href={`mailto:${row.email}`}>{row.email}</a> },
      width: '150px',
      wrap: true,
      sortable: true
    },
    {
      name: "Join Date",
      // selector: row => row.created_at ,
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      sortable: true
    },
    {
      name: "Due Commission",
      selector: row => <div>$ {row.due_commission}</div>,
      sortable: true
    },
    {
      name: "Total Commission",
      selector: row => <div>$ {row.total_commission}</div>,
      sortable: true
    },
    {
      name: "Actions",
      cell: row => {
        if(row.total_commission > 0){
          return (
            <div><Button variant="primary mr-1" onClick={() => this.    handleClose(row.userID,row.type)}>View</Button>
           </div>
          )
        }
      
      }
      ,
      sortable: true,
      width: '200px'
    },
    // {
    //   name: "Status",
    //   cell: row =>
    //   {
    //     return(
    //       <div>{row.has_paid==1?'Paid':'Pending'}</div>



    //         )
    //         } 
    //        ,
    //   sortable: true
    // },



  ];





  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }

    this.getAllList()

    //help api
    let requestHelp = {
      end_point: '/help-details?key=affiliate_employee_commission_list',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })



  }

  getAllList(type = '') {
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError
    let requestUrl = ''
    let start_date = FromData.start_date
    let end_date = FromData.end_date
    if (type == 'due') {

      sta.setState({ type: 'GBTW' })
      requestUrl = '/employee-commission-list?due=' + 1 + '&to_date=' + moment(start_date).format("YYYY-MM-DD") + '&from_date=' + moment(end_date).format("YYYY-MM-DD")
    }else if(type == "nexgen"){
      sta.setState({ type: 'nexgen' })
      requestUrl = '/employee-commission-list?nexgen=' + 1 + '&to_date=' + moment(start_date).format("YYYY-MM-DD") + '&from_date=' + moment(end_date).format("YYYY-MM-DD")
    } else {
      sta.setState({ type: 'GBTW' })
      requestUrl = '/employee-commission-list?to_date=' + moment(start_date).format("YYYY-MM-DD") + '&from_date=' + moment(end_date).format("YYYY-MM-DD")
    }

    let request = {
      end_point: requestUrl,
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        let allCommissionList = resp.data

        sta.setState({ allCommissionList })

        FromData['start_date'] = resp.from_date;
        FromDataError['start_date'] = (resp.from_date != null) ? '' : 'false'

        FromData['end_date'] = resp.to_date;
        FromDataError['end_date'] = (resp.to_date != null) ? '' : 'false'

        sta.setState({ FromData })
        sta.setState({ FromDataError })

        let payment_start_date = resp.from_date
        let payment_end_date = resp.to_date
        sta.setState({ payment_start_date })
        sta.setState({ payment_end_date })

        // sta.setState({start_date})
        // sta.setState({end_date})

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });

  }

  updateState = state => {

    let FromDataError1 = this.state.FromDataError1;
    let allData = []
    state.selectedRows.map((item, key) => {

      if(item.due_commission > 0){
        allData.push(item.userID);
      }
      
    })
    let FromData = this.state.FromData
    FromData.allpaymentId = allData
    this.setState({ FromData })
    // console.log('payment id',this.state.FromData.allpaymentId)

    // FromDataError1.contact_no = 'false';
    // this.setState({FromDataError1})


  }

  PayCommission() {


    let sta = this;
    let FromData = this.state.FromData;

    var formdata = new FormData();
    let allpaymentId = JSON.stringify(FromData.allpaymentId)
    // console.log('payment',allpaymentId)

    if (allpaymentId != '[]') {
      sta.setState({ loader: 'visible' })///loader on
      let start_date = ''
      let end_date = ''
      if (this.state.payment_start_date && this.state.payment_end_date) {


        start_date = this.state.payment_start_date
        end_date = this.state.payment_end_date
      }
      let requestpay = {};
      if(this.state.type == "nexgen"){
        requestpay = {
          end_point: '/pay-employee-commission?type=nexgen&userID=' + allpaymentId + '&to_date=' + start_date + '&from_date=' + end_date,
          token: localStorage.getItem("gs_token")
        };
      }else{
        requestpay = {
          end_point: '/pay-employee-commission?userID=' + allpaymentId + '&to_date=' + start_date + '&from_date=' + end_date,
          token: localStorage.getItem("gs_token")
        };
      }
      GET_API(requestpay).then(respPay => {

        sta.setState({ loader: 'hidden' })///loader off


        if (respPay.status == 'success') {
          if(this.state.type == "nexgen"){
            this.getAllList("nexgen")
          }else{
            this.getAllList()
          }
          sta.setState({ Msg: 'Successfully Paid !', Msgtype: 'success' })
          let FromData = this.state.FromData
          FromData.allpaymentId = []
          this.setState({ FromData })
          //FromData = respPay.data

          //sta.setState({FromData})


        }
      }).catch((ee) => {

        sta.setState({ loader: 'hidden' })///loader off

        console.log('/affiliatecountERR', ee)
      });


    }
    else {
      sta.setState({ errMsg: 'Please select atleast one user which have pending due', Msg: '' })
    }



  }

  handleStartDateChange(startD) {
    let FromData = this.state.FromData
    FromData.start_date = moment(startD).format("YYYY-MM-DD")
    this.setState({ FromData })
  }
  handleEndDateChange(endD) {
    let FromData = this.state.FromData
    FromData.end_date = moment(endD).format("YYYY-MM-DD")
    this.setState({ FromData })
  }

  searchChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let start_date = ''
    let end_date = ''
    if (this.state.payment_start_date && this.state.payment_end_date) {
      start_date = this.state.payment_start_date
      end_date = this.state.payment_end_date
    }


    let sta = this;
    let FromData = this.state.FromData;
    let requestS = {
      end_point: '/employee-commission-list?search=' + value + '&to_date=' + moment(start_date).format("YYYY-MM-DD") + '&from_date=' + moment(end_date).format("YYYY-MM-DD"),
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(requestS).then(respS => {

      sta.setState({ loader: 'hidden' })///loader off


      if (respS.status == 'success') {

        let allCommissionList = respS.data

        sta.setState({ allCommissionList })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });

  }




  render() {
    let FromData = this.state.FromData

    return (
      <div>
        {FromData != null ? (<>


          <Container fluid className="dash_container">
            <Row>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12}>
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                <div className="tabpanelOneForm pt-3">
                  {(this.state.member_id != 0) ? (<>
                    <MemberCommissionViewModal {...this.props} member_id={this.state.member_id} type={this.state.type}  start_date={this.state.payment_start_date} end_date={this.state.payment_end_date} show={this.state.memberViewModal} close={() => this.handleClose()} />
                  </>) : (<></>)}

                  <h3>Employee Commission List</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.help,
                    }}
                  ></p>
            
                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : this.state.errMsg !== '' ? (<><FlashMess m={this.state.errMsg} mtype='danger' /></>) : (<></>)}

                  <Form
                    className="tabform"
                  // onSubmit={this.searchCommission}
                  >
                    <Row className='justify-content-end'>
                      <Col md="3">From Date
                        <DatePicker
                          className='pl-2'
                          selected={FromData.start_date == "Invalid date" ? "" : new Date(FromData.start_date)}
                          name="start_date"
                          placeholderText="mm-dd-yyyy"
                          dateFormat="MM-dd-yyyy"
                          onChange={this.handleStartDateChange}
                        />
                      </Col>
                      <Col md="3"> To Date
                 
                        <DatePicker
                          className='pl-2'
                          selected={FromData.end_date == "Invalid date" ? "" : new Date(FromData.end_date)}
                          name="end_date"
                          placeholderText="mm-dd-yyyy"
                          dateFormat="MM-dd-yyyy"
                          onChange={this.handleEndDateChange}
                        />
                        
                      </Col>
                      <Col md="2" className="mt-4" >
                        <select onClick={(e) => {
                          if(e.target.value == ""){
                            this.getAllList()
                          }else{
                            this.getAllList(e.target.value)
                          }

                          }} style={{"width" :"140px"}}>
                           <option  value= "">GBTW All</option>
                          <option value = 'due' >GBTW Only Due</option>
                          <option value ='nexgen' >Nexgen Affiliate</option>
                        </select>
                        
                      </Col>
                      <Col md="2" className="mt-4" >
                          <Button as="button" onClick={() => this.PayCommission()} >Pay</Button>
                      </Col>
                    </Row>
                  </Form>

                  


                  <div className="table_search" style={{ margin: '10px', float: "right" }}>
                    Search : <input type='text' placeholder='Search' name="search_val" className="mt-4" onChange={this.searchChange} /></div>
                  <DataTable
                    title=""
                    columns={this.columns}
                    data={this.state.allCommissionList}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    paginationPerPage={10}
                    selectableRows={true}
                    onSelectedRowsChange={this.updateState}
                    noHeader={true}
                  />


                </div>



              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}


      </div>




    );
  }
}

