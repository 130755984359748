export const SHOW_ON_PROD = false;
export const BASE_URL = "https://app.nexgenaiaffiliates.io/";
export const API_URL = "https://api.nexgenaiaffiliates.io/api";
export const PayPal = ""
export const CaptchaKey = "6LdnyO8lAAAAAGK-MFxWiEkYeirIOxJ3_RBcicEx";
export const ResumeScoringURL = "https://resumescoring.io";
export const SocialScoringURL = "https://socialprofilescoring.io";

export const JobAlertsURL = "https://www.jobalerts.io";
export const CoachmasterURL = "https://login.coachmaster.io";
export const ResumeCertifiedURL = "https://login.resumecertified.org";
export const ResumeSendingURL = "https://login.resumesending.com";
export const JobseekerURL = "https://premium.jobseekernewshubb.com";
export const resumeboostURL = "https://login.resumeboost.io";
export const GBTW_URL = "https://globalworkinitiative.org";

export const resumecertified_FRONT_URL = "https://resumecertified.org/";
export const resumesending_FRONT_URL = "https://resumesending.com/";
export const coachmaster_FRONT_URL = "https://coachmaster.io/";
export const careermaster_FRONT_URL = "http://careermaster.io/";
export const jobalerts_FRONT_URL = JobAlertsURL;
export const careerbooks_FRONT_URL = "https://careerbooks.io/"
export const jobseekernewshubb_FRONT_URL = JobseekerURL;
export const recruiterdirect_FRONT_URL = "https://recruiterdirect.io/";
export const hrdirect_FRONT_URL = "https://hrdirect.io/";
export const freeresumeaudit_FRONT_URL ="https://freeresumeaudit.com/";
export const BASE_API_URL = "https://api.nexgenaiaffiliates.io/";
export const STRIPE_KEY = "pk_live_0kSfy0FdzZVwbzxVNsmApICh00d6L61Iyr";

export const STUDENT_PROMO = "GBW508WNPXO3H";
export const STUDENT_PROMO2 = "GBW49OU1R8YXE";
