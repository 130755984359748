import React, { Component } from 'react'
import { Container, Row, Col, Tab, Nav, Form, Button, Breadcrumb, Accordion, Card, Carousel } from 'react-bootstrap';

export default class AffiliateReffer extends Component {
    constructor(props)
    {
        super(props)
    }

    componentDidMount(){
        let code = this.props.match.params.code
        let site = this.props.match.params.role
         localStorage.setItem('affiliate_code',code)

         if(site=='jobseeker')
         {
            this.props.history.push({
                pathname: '/becomejobseeker',
                
      
              })
         }
         else if(site=='sponser')
         {
            this.props.history.push({
                pathname: '/becomesponsor',
                
      
              })

         }
         else
         {
            this.props.history.push({
                pathname: '/login',
                state:{Msg:'Not a valid link',MsgType:'danger'}
      
              })

         }
        
    
         
      
       }
  
    render() {


    return (
        <div className="bannertop">
        {/* <img className='pl-4 pt-4 imgSize' src={logo10} /> */}
        <Container className="banner_container_home justify-content-between">
          <Row >
            <Col md={{span:12, offset: 1}} className="pr-4 left_content">
            <p className='job_l'>Please Wait</p>
            {/* <Button className="btn_header ml-1/ sp_btn_fix_l" href="#/becomesponsor">Become Sponsor</Button> */}
            </Col>

         
          </Row>

        </Container>
      </div>
    )
  }
}
