import React, { Component, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
  Dropdown
} from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from "../Validate/validator";
import Leftpanel from "./Section/LeftPanel";
import csvfilename from "./files/add-contact.csv";
import "./css/dashboard.css";
import DataTable from "react-data-table-component";
import CampaingViewModal from "./Section/CampaingViewModal";
import Threedots_img from '../images/dotsss.png'
import moment from "moment";

export default class AffiliateBankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FromData: {
        campaign_name: "",
        description: "",
        allcontactID: "",
      },
      FromDataError: {
        campaign_name: "false",
        description: "false",
        allcontactID: "false",
      },
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/add-affiliate-bank",
      ContactData: [],
      ContactDataError: [],

      TemplateData: [],
      campaign_id: 0,
      CampaignViewModal: false,
      help: "",
    };

    //Binding
  }

  handleClose(campaign_id = 0) {
    if (this.state.CampaignViewModal) {
      this.setState({ CampaignViewModal: false });
    } else {
      this.setState({ campaign_id: campaign_id, CampaignViewModal: true });
    }
  }

  columns = [
    {
      name: "SL. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Account Holder Name",
      selector: (row) => row.holder_name,
      sortable: true,
      width: "250px",
      wrap: true,
    },

    {
      name: "Account Number",
      selector: (row) =>
        row.is_default == 1 ? (
          <>
            {row.account_number}
            <span className="badge badge-success">Default</span>
          </>
        ) : (
          <>{row.account_number}</>
        ),
      // sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Bank Name",
      selector: (row) => row.bank_name,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "SWIFT Code",
      selector: (row) => row.IFSC_code,
      width: "150px",
      sortable: true,
      wrap: true
    },
    {
      name: "ABA-ACH Number",
      selector: (row) => row.aba_ach_number,
      width: "100px",
      sortable: true,
      wrap: true
    },

    {
      name: "Created At",
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      // selector: (row) => row.created_at,
      width: "150px",
      sortable: true,
      wrap: true,
    },

    // {
    //   name: "Actions",
    //   cell: (row) => {
    //     return (
    //       <div>
    //         {row.is_default == 1 ? (
    //           <></>
    //         ) : (
    //           <>
    //             <Button style={{margin:'5px'}}
    //               className="pl-1"
    //               disabled={false}
    //               variant="info"
    //               onClick={() => {
    //                 if (window.confirm("Set this default account ?")) {
    //                   this.handaleDefault(row.bankID);
    //                 }
    //               }}
    //             >
    //               Set Default
    //             </Button>
    //           </>
    //         )}
    //         <a href={"#/edit-bank/" + row.bankID}>
    //           <Button variant="primary mr-1">Edit</Button>
    //         </a>

    //         <Button
    //           disabled={false}
    //           variant="danger"
    //           onClick={() => {
    //             if (window.confirm("Delete the Bank?")) {
    //               this.handaleDelete(row.bankID);
    //             }
    //           }}
    //         >
    //           Delete
    //         </Button>
    //       </div>
    //     );
    //   },
    //   // sortable: true,
    //   width: "300px",
    // },
    {
      name: "Actions",
      cell: (row) => {
        return (


          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  {row.is_default == 1 ? (
                    <></>
                  ) : (
                    <>
                      <Button style={{ margin: '5px' }}
                        className="pl-1"
                        disabled={false}
                        variant="info"
                        onClick={() => {
                          if (window.confirm("Set this default account ?")) {
                            this.handaleDefault(row.bankID);
                          }
                        }}
                      >
                        <i className="fa fa-window-restore"></i> Set Default
                      </Button>
                    </>
                  )}
                </Dropdown.Item>

                {/* <Dropdown.Item >
                    <a href={"#/edit-bank/" + row.bankID}>
                      <Button variant="primary mr-1"> <i className="fa fa-pencil"></i> Edit</Button>
                    </a>
                  </Dropdown.Item> */}
                <Dropdown.Item >
                  <Button disabled={false} variant="danger"
                    onClick={() => {
                      if (window.confirm("Delete the Bank?")) {
                        this.handaleDelete(row.bankID);
                      }
                    }}
                  >
                    <i className="fa fa-trash"></i>  Delete
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>


          // <div>
          //   {row.is_default == 1 ? (
          //     <></>
          //   ) : (
          //     <>
          //       <Button style={{margin:'5px'}}
          //         className="pl-1"
          //         disabled={false}
          //         variant="info"
          //         onClick={() => {
          //           if (window.confirm("Set this default account ?")) {
          //             this.handaleDefault(row.bankID);
          //           }
          //         }}
          //       >
          //         Set Default
          //       </Button>
          //     </>
          //   )}


          //   <a href={"#/edit-bank/" + row.bankID}>
          //     <Button variant="primary mr-1">Edit</Button>
          //   </a>

          //   <Button
          //     disabled={false}
          //     variant="danger"
          //     onClick={() => {
          //       if (window.confirm("Delete the Bank?")) {
          //         this.handaleDelete(row.bankID);
          //       }
          //     }}
          //   >
          //     Delete
          //   </Button>
          // </div>
        );
      },
      // sortable: true,
      width: "100px",
    },
  ];

  handaleDefault(bankID) {
    let sta = this;

    var formdata = new FormData();
    formdata.append("bankID", bankID);

    let request = {
      end_point: "/setdefault-bankdetails",
      formdata: formdata,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    POST_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader on

        if (resp.status == "success") {
          sta.setState({
            Msg: "Set Default Successfully!",
            Msgtype: "succcess",
          });

          setTimeout(() => {
            window.location.reload()

          }, 3000);

          this.getAllBank();
        } else {
          //console.log(resp.message.password.join())
          sta.setState({ Msg: "Set Default Failed", MsgType: "danger" });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader on
        sta.setState({ Msg: "Some Thing Went wrong", MsgType: "danger" });
      });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let sta = this;
    let ContactData = this.state.ContactData;
    let ContactDataError = this.state.ContactDataError;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    sta.getAllBank();

    //help api
    let requestHelp = {
      end_point: "/help-details?key=affiliate_bank_details",

      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestHelp)
      .then((resph) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resph.status == "success") {
          let help = resph.data.value;
          sta.setState({ help });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });
  }

  getAllBank() {
    let sta = this;
    let ContactData = this.state.ContactData;
    let ContactDataError = this.state.ContactDataError;

    let request = {
      end_point: "/list-bankdetails",
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          ContactData = resp.data;

          sta.setState({ ContactData });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/campaign list ERR", ee);
      });
  }

  handaleDelete(id) {
    let sta = this;

    var formdata = new FormData();
    formdata.append("bankID", id);

    let request = {
      end_point: "/delete-bankdetails",
      formdata: formdata,
      token: localStorage.getItem("gs_token"),
    };

    POST_API(request)
      .then((resp) => {
        if (resp.status == "success") {
          sta.getAllBank();
          sta.setState({ Msg: "Deleted Successfully!", Msgtype: "succcess" });
        } else {
          //console.log(resp.message.password.join())
          sta.setState({ Msg: "faild", MsgType: "danger" });
        }
      })
      .catch(() => { });
  }

  render() {
    let FromData = this.state.FromData;
    let ContactData = this.state.ContactData;

    return (
      <div>
        {FromData.hasOwnProperty("campaign_name") ? (
          <>
            <Container fluid className="dash_container">
              <Row>
                {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
                <Col md={12}>
                  <Loader
                    loadmsg="please wait"
                    visibility={this.state.loader}
                  />

                  <div className="tabpanelOneForm pt-3">
                    {this.state.campaign_id != 0 ? (
                      <>
                        <CampaingViewModal
                          {...this.props}
                          campaign_id={this.state.campaign_id}
                          show={this.state.CampaignViewModal}
                          close={() => this.handleClose()}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    <Row>
                      <Col md={9}>
                        <div>
                          {this.state.Msg !== "" ? (
                            <>
                              <FlashMess
                                m={this.state.Msg}
                                mtype={
                                  this.state.MsgType
                                    ? this.state.MsgType
                                    : "success"
                                }
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <h3>Bank details</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.help,
                          }}
                        ></p>
                        {/*<div
                      className="tooltip-block-redeem-list"
                      style={{ left: "180px",top:"7px" }}
                    >
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.help,
                              }}
                            ></div>
                          </Tooltip>
                        }
                      >
                        
                        
                      </OverlayTrigger>
                     </div> */}
                      </Col>

                      <Col md={3} className="d-flex justify-content-end align-items-start">
                        <a
                          href="#/add-bank"
                          className="btn btn-primary ghost_button mt-3 mb-4"
                        >
                          Add Bank Details
                        </a>
                      </Col>
                    </Row>
<div className="table-responsive">
                    <DataTable
                      title=""
                      columns={this.columns}
                      data={ContactData}
                      defaultSortField="title"
                      pagination={true}
                      NoDataComponent={"No data"}
                      noHeader={true}
                    />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <p>....Loading</p>
        )}
      </div>
    );
  }
}
