import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../../Helpers/Helpers";
import validation from '../../Validate/validator';
import Leftpanel from '../Section/LeftPanel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye,faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

class CreateMember extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        list:{},
                        FromData: {
                            memberType : '',
                            member_contact_no : '',
                            member_firstname : '',
                            member_lastname : '',
                            member_email : '',
                            member_password : '',
                            assign_cap : ''
                          
                        },
                        FromDataError :
                                        {
                                            memberType : 'false',
                                            member_contact_no : 'false',
                                            member_firstname : 'false',
                                            member_lastname : 'false',
                                            member_email : 'false',
                                            member_password : 'false',
                                            assign_cap : 'false'
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/members',
                        inputType:'password',

                    }



                    ///bind
                    this.handaleChange = this.handaleChange.bind(this)
                    this.handaleSubmit = this.handaleSubmit.bind(this)
                    this.toggleEye=this.toggleEye.bind(this)
    }

    handalePhoneNoChange(data) {
      let FromData = this.state.FromData;
      let FromDataError = this.state.FromDataError;
      let value = '';
      FromData['member_contact_no'] = data;
      this.setState({ FromData }, () => {
  
      })
  
      if (FromData.member_contact_no != '') {
        var valid_obj = {
          value: value,
          rules: 'required',
          message: 'This field is required'
        }
        validation(valid_obj).then((error) => {
          FromDataError['member_contact_no'] = true
          this.setState({ FromDataError }) //from error state
        })
      }
  
    }  
    
    validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;
  
        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {
  
            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
  };
  




    handaleChange(data)
  {
    if(data.target.type != "number" && data.target.type != "email"){
      const pointer = data.target.selectionStart;
      const element = data.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
    }
  
      let sta = this;
      let {FromDataError,FromData} = sta.state;
      

      let name = data.target.name;
      let value =  data.target.value;
      FromData[name] = value;
    
      
     var valid_obj = {
        value:value,
        rules:data.target.getAttribute("validaterule"),
        message:data.target.getAttribute("validatemsg")
      }


    
      validation(valid_obj).then((error)=>{

       
       
        FromDataError[name] = error
        this.setState({FromDataError})
       

        this.setState({FromData},()=>{

         // console.log(FromDataError)
          
        })
      })
    
       

       
      
  }

  handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let {FromDataError,FromData} = this.state;


        if(this.validateForm(FromDataError))
        {
          sta.setState({loader:'visible'})///loader on

                    var formdata = new FormData();

                    if( FromData.memberType == 'Employee')
                    {
                    formdata.append("roleID", 3);
               

                    }
                    else{
                    formdata.append("roleID", 7);
                  


                    }
                 
                   

                    formdata.append("firstname", FromData.member_firstname);
                    formdata.append("lastname", FromData.member_lastname);
                    formdata.append("email", FromData.member_email);
                    formdata.append("password", FromData.member_password);
                    formdata.append("contact_no", FromData.member_contact_no);
                    formdata.append("assign_cap", FromData.assign_cap);
                    formdata.append("employerID",JSON.parse(localStorage.getItem("gs_user")).userID);





                        let request = {
                                    end_point : '/create-employee',
                                    formdata:formdata,
                                    token: localStorage.getItem("gs_token")
                                    };


                        POST_API(request).then((resp)=>{
                         sta.setState({loader:'hidden'})///loader on


                            if(resp.status=='success')
                            {
                                
                                


                                sta.props.history.push({
                                    pathname: '/members',
                                    state: { Msg:'User Created Successfully!',Msgtype:'succcess'}
                                })
                                
                                
                                
                            }
                            else{
                                //console.log(resp.message.password.join())
                                sta.setState({Msg:resp.message.email.join(),MsgType:'danger'})
                            }

                        }).catch(()=>{
                          sta.setState({loader:'hidden'})///loader on
                          sta.setState({Msg:'Something Went Wrong,Please try again later.',MsgType:'danger'})
                       


                        })


        }
        else
        {
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
        }


       

    }

// password hide and show
toggleEye()
{
 var temp = document.getElementById("password");
       if (temp.type === "password") {
           this.setState({inputType:'text'});
          
       }
       else 
       {
         this.setState({inputType:'password'});
           
       }
}




    render() {
      const eye = <FontAwesomeIcon icon={faEye} />;
      const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
        let {FromData} = this.state
        return (
            <div>
                 {'a'=='a'? (<>
            

            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12} className="pt-4 pb-4">
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm card p-5">

            <h3>Create Member</h3>
                {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
                
                


                <div className="tabpanelOneForm">
              <Form className="tabform" onSubmit={this.handaleSubmit}>
                  
                    <Row className="m-0 mt-4 mb-4">
                            <Col md={12} className="pl-0  ">
                             
                              <Form.Row>

                              <Form.Group as={Col} controlId="formGridState" className='col-md-6'>
                                    <Form.Label>Member Type
                                      <span className="text-danger"> *</span>
                                    </Form.Label>
                                    <Form.Control
                                    className='w-100'
                                     validaterule={['required']}
                                     validatemsg={['This field is required']}
                                     as="select" defaultValue="Type" name="memberType" onChange={this.handaleChange}>
                                    <option value="">select</option>
                                    <option>Manager</option>
                                    <option>Employee</option>
                                    </Form.Control>
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.memberType=='false'?'': this.state.FromDataError.memberType}</p>

                                </Form.Group>

                             
                                </Form.Row>
                                <Form.Row>

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>First Name
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="member_firstname"
                                    value={FromData.member_firstname}
                                    onChange={this.handaleChange}
                                    placeholder="Enter  first name"
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_firstname=='false'?'': this.state.FromDataError.member_firstname}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Last Name
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="member_lastname"
                                    value={FromData.member_lastname}
                                    onChange={this.handaleChange}
                                    placeholder="Enter  last name"
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_lastname=='false'?'': this.state.FromDataError.member_lastname}</p>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Email
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="member_email"
                                    value={FromData.member_email}
                                    onChange={this.handaleChange}
                                    placeholder="Enter  email"
                                    validaterule={['required','isEmail']}
                                    validatemsg={['This field is required','Invalid Email']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_email=='false'?'': this.state.FromDataError.member_email}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Phone
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="US"
                                    value={FromData.member_contact_no}
                                    name="member_contact_no"
                                    onChange={this.handalePhoneNoChange.bind(this)}
                                    validaterule={['required', 'phone']}
                                    validatemsg={['This field is required', 'Invalid Phone number']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_contact_no=='false'?'': this.state.FromDataError.member_contact_no}</p>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Assign CAP
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="assign_cap"
                                    min="1" max="10"
                                    onChange={this.handaleChange}
                                    value={FromData.assign_cap}
                                    placeholder="Ex - 10"
                                    validaterule={['required','assign_cap', "notNegative"]}
                                    validatemsg={['This field is required', 'must be less than or equal 10', 'Invalid number']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.assign_cap=='false'?'': this.state.FromDataError.assign_cap}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Password
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type={this.state.inputType}
                                    id='password'
                                    name="member_password"
                                    onChange={this.handaleChange}
                                    value={FromData.member_password}
                                    placeholder="Enter Password"
                                    validaterule={['required','password']}
                                    validatemsg={['This field is required','password should be 6 charecter']}
                                  />
                                    <i  style={{  float: 'right', marginTop: '-35px', marginRight:'8px', position: 'relative', zIndex: 1, cursor:'pointer'}} onClick={this.toggleEye}>{this.state.inputType=='text'?eyeSlash:eye}</i>
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_password=='false'?'': this.state.FromDataError.member_password}</p>
                                </Form.Group>
                              </Form.Row>
                            </Col>
                            <Col md={12} className="pl-0">
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn btn-primary btn-md tabformbutton"
                                disabled={this.state.loader!='hidden'? true : false}
                              >
                                Submit
                              </Button>
                            </Col>
                         
                        
                    </Row>
                    </Form>
                      
            </div>
               
                   
            </div>
            </Col>     
          </Row>
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
        );
    }
}

export default CreateMember;