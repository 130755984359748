import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";

import Leftpanel from '../Section/LeftPanel';
import DataTable from "react-data-table-component";
import { BASE_URL } from '../../Config';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class UserList extends Component {

  constructor(props) {
    super(props)
    this.state = {

      FromData: {},
      FromDataError:
        {},
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/members',
      help: '',
      totalRows: 0,
      offset: 1,
      limit: 10,

    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this)
    this.handaleSubmit = this.handaleSubmit.bind(this)


  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  columns = [
    {
      name: "SL No",
      selector: (row, index) => row.sl_no,
      width: '80px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Member Type",
      cell: row => row.roleID == 3 ? <span className="badge badge-primary">Employee</span> : <span className="badge badge-info">Manager</span>,
      // sortable: true
    },
    {
      name: "Status",
      cell: row => row.is_activated == 0 ? <span className="badge badge-primary">Active</span> : <span className="badge badge-danger">Inactive</span>,
      // sortable: true
    },
    
    {
      name: "Name",
      selector: row => row.firstname + ' ' + row.lastname,
      sortable: true,
      width: '200px',
    },
    {
      name: "Email",
      selector: "email",
      width: '200px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Max Sponsership",
      selector: "maxSponsership",
      width: '150px',
      sortable: true,
      wrap: true,
    },
    // {
    //   name: "Employee Name",

    //   cell: row => row.employee.firstname+' '+row.employee.lastname ,
    //   sortable: true
    // },
    {
      name: "Created at",
      selector: "created_at",
      width: '150px',
      sortable: true,
      wrap: true,

    },
    {
      name: "Jobseeker Joined",
      // cell: row => row.jobseeker_count ,
      selector: row => row.jobseeker_count,
      sortable: true,
    },

    {
      name: "Action",
      cell: row => {
        return (
          <div className='d-flex'><a href={'#/update-members/' + row.userID}><Button variant="primary mr-1">Edit</Button></a>
            <Button className="pl-3 pr-3" variant={row.is_activated == 0 ? 'danger' : 'info'} onClick={() => { if (window.confirm(row.is_activated == 0 ? 'De-activate the Member?' : 'Activate the Member?')) { this.handaleDelete(row.userID) }; }}>{row.is_activated == 0 ? 'De-activate' : 'Activate'}</Button>
          </div>
        )
      }
      ,
      // sortable: true,
      width: '220px'
    },
  ];



  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = '';
    let FromData = this.state.FromData;





  }

  handaleSubmit(e) {

    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    // console.log(this.state)
    // return 0;



  }

  handaleDelete(id) {
    let sta = this

    var formdata = new FormData();
    formdata.append("userID", id);

    let request = {
      end_point: '/deactive-employee',
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };




    POST_API(request).then((resp) => {

      if (resp.status == 'success') {

        // console.log("ressss",resp.data.is_activated)
        if (resp.data.is_activated != 0) {
          sta.setState({ Msg: 'User inactivated successfully!', Msgtype: 'succcess' })
        } else {
          sta.setState({ Msg: 'User Activated Successfully!', Msgtype: 'succcess' })
        }
        setTimeout(() => {
          window.location.reload()

        }, 3000);





      }
      else {
        //console.log(resp.message.password.join())
        sta.setState({ Msg: resp.message.email.join(), MsgType: 'danger' })
      }

    }).catch(() => {

    })

  }



  handlePageChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    var formdata = new FormData();
    formdata.append("employerID", JSON.parse(localStorage.getItem("gs_user")).userID);
    let request = {
      end_point: `/employee-List?offset=${page}&limit=${this.state.limit}`,
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };


    this.setState({ loader: 'visible' })///loader on


    POST_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'Success') {
        let FromData = resp.data;
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });


  };





  handlePerRowsChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    var formdata = new FormData();
    formdata.append("employerID", JSON.parse(localStorage.getItem("gs_user")).userID);
    let request = {
      end_point: `/employee-List?offset=${this.state.offset}&limit=${page}`,
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };

    this.setState({ loader: 'visible' })///loader on


    POST_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'Success') {
        let FromData = resp.data;
        this.setState({ FromData })
        this.setState({ limit: page });

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };

  handleSearchChange = async (searchValue) => {
    var formdata = new FormData();
    formdata.append("employerID", JSON.parse(localStorage.getItem("gs_user")).userID);
    let request = {
      end_point: `/employee-List?offset=${this.state.offset}&limit=${this.state.limit}&searchValue=${searchValue}`,
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };


    this.setState({ loader: 'visible' })///loader on


    POST_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'Success') {
        let FromData = resp.data;
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };


  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }
    var formdata = new FormData();
    formdata.append("employerID", JSON.parse(localStorage.getItem("gs_user")).userID);


    let request = {
      end_point: `/employee-List?offset=${this.state.offset}&limit=${this.state.limit}`,
      formdata: formdata,
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    POST_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'Success') {

        FromData = resp.data
        sta.setState({ totalRows: resp.row_count });
        sta.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });


    //help api
    let requestHelp = {
      end_point: '/help-details?key=sponsor_member',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })





  }


  render() {
    let FromData = this.state.FromData

    return (
      <div>
        {FromData != null ? (<>


          <Container fluid className="dash_container" style={{overflow:"scroll"}}>
            <Row>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12} className="pt-4 pb-4">
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                <div className="tabpanelOneForm">


                <Row>
                    <Col md={9}>
                      <div>
                      {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                      </div>
                      <h3>Member List</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.help
                        }}></p>
                    </Col>
                    <Col md={3}>
                        <a href="#/create-members"><button className="btn btn-primary ghost_button mt-3 mb-4" style={{ float: "right" }}>Add Member</button></a>
                    </Col>
                  </Row>


                  <div className="table_search" style={{float: "right",margin:'10px'}}>   
                Search : 
                <input type='text' 
                placeholder='Search' 
                name="search_val"  
                onChange={(e) => this.handleSearchChange(e.target.value)}
                /></div>



                  <DataTable
                    title=""
                    columns={this.columns}
                    data={FromData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                    paginationServer
                    paginationTotalRows={this.state.totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                  />


                </div>



              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}


      </div>




    );
  }
}

export default UserList;